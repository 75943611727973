import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { LayersControl, useMap, GeoJSON } from 'react-leaflet';

import { api } from 'services/api';
import { subStationIcon } from './substationIcon';
import { type BBOXdata } from './BBOXdata';

export const ElectricLinesLayer: React.FC = (props) => {
  const [isLayerVisible, setLayerVisibility] = useState(true);
  const map = useMap();
  const [geojsonData, setGeojsonData] = useState<
    GeoJSON.GeoJsonObject | undefined
  >(undefined);

  const layerRef = useRef<any>();
  const [oldBbox, setOldBbox] = useState<BBOXdata>();

  const fetchGeoJSONData = async (): Promise<void> => {
    const currentBounds = map.getBounds();

    const currentBboxData = {
      lat: {
        min: currentBounds.getSouthWest().lat,
        max: currentBounds.getNorthEast().lat,
      },
      long: {
        min: currentBounds.getSouthWest().lng,
        max: currentBounds.getNorthEast().lng,
      },
    };

    const shouldFetch = (): boolean => {
      if (oldBbox == null) return true;

      const latMin = oldBbox.lat.min <= currentBboxData.lat.min;
      const latMax = currentBboxData.lat.max < oldBbox.lat.max;
      const longMin = oldBbox.long.min < currentBboxData.long.min;
      const longMax = currentBboxData.long.max < oldBbox.long.max;

      const newLatContained = latMin && latMax;
      const newLongContained = longMin && longMax;
      const newBboxContained = newLatContained && newLongContained;

      // console.log(
      //   'BBox SHould Update',
      //   !newBboxContained,
      //   oldBbox,
      //   currentBboxData,
      // );

      return !newBboxContained;
    };

    if (!shouldFetch()) {
      return;
    }

    setOldBbox(currentBboxData);

    const latMin = currentBounds.getNorthEast().lat.toFixed(2);
    const latMax = currentBounds.getSouthWest().lat.toFixed(2);

    const longMin = currentBounds.getSouthWest().lng.toFixed(2);
    const longMax = currentBounds.getNorthEast().lng.toFixed(2);

    const bbox = `long_min=${longMin}&lat_min=${latMin}&long_max=${longMax}&lat_max=${latMax}`;
    const url = `/map/lines?${bbox}`;

    try {
      const response = await api.get(url, {
        // skipAuthRefresh: true,
      } as any);
      setGeojsonData(response.data);
    } catch (error) {
      console.error('Error fetching GeoJSON data:', error);
    }
  };

  const onEachFeature = (feature: any, layer: any): void => {
    // Create a tooltip but don't open it immediately
    const tooltip = L.tooltip({
      permanent: false,
      direction: 'top',
    });

    // Additional mouseover event to display more properties if needed
    const lineId: string = feature?.properties?.idr;
    const lineTension: string = feature?.properties?.tension;
    layer.on('click mouseover mousemove', (e) => {
      const tooltipContent = `Name: ${lineId}<br> Tension: ${lineTension} <br>Line Type: ${
        feature?.properties.configuration as string
      }`;
      tooltip.setContent(tooltipContent);

      // Set tooltip position and open it
      tooltip.setLatLng(e.latlng);
      map.openTooltip(tooltip);
    });

    layer.on('mouseout', (e) => {
      map.closeTooltip(tooltip);
    });
  };

  const layerStyle = (feature: any): any => {
    const lineType = feature?.properties.configuration;
    const lineDashing =
      lineType === 'Souterraine'
        ? null
        : lineType === 'Aerienne'
        ? '10 10'
        : '10 50 50 10';

    // const defaultLineWeight = 2;
    const defaultLineWeight = 4;
    const defaultFillOpacity = 1; // 0.5;

    switch (feature?.properties.tension_idr) {
      case 0: // hors tension
        return {
          className: 'stroke-polyline',
          // color: 'black',
          // fillColor: 'rgb(10, 10,10)',

          weight: 0.1,
          // weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 1: // <45kV
        return {
          className: 'stroke-polyline',
          color: 'rgb(80, 10,10)',
          fillColor: 'rgb(80, 10,10)',

          // weight: 0.2,
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 2: // 45kV
        return {
          className: 'stroke-polyline',
          color: 'rgb(50, 50,150)',
          // color: 'rgba(0,0,0, 0.3)',
          // opacity: 0.1,

          fillColor: 'rgb(50, 50,150)',

          // weight: 0.5,
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 3: // 63kV
        return {
          className: 'stroke-polyline',

          color: 'hsl(273, 38%, 61%)',
          // color: 'rgb(50, 150,50)',
          // color: 'rgba(0,0,0, 0.3)',
          // opacity: 0.1,

          fillColor: 'rgb(50, 150,50)',

          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 4: // 90kV
        return {
          className: 'stroke-polyline',

          color: 'hsl(273, 38%, 61%)',
          // color: 'yellow',
          // color: 'rgba(0,0,0, 0.3)',
          // opacity: 0.1,

          fillColor: 'yellow',

          // weight: 2,
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 5: // 150kV
        return {
          className: 'stroke-polyline',

          // color: 'black',
          fillOpacity: defaultFillOpacity,

          // color: 'rgba(0,0,0, 0.3)',
          // opacity: 0.9,

          fillColor: 'hsl(240, 39%, 61%)',
          // fillColor: 'rgb(0,255,0)',

          // weight: 2,
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 6: // 225kV
        return {
          className: 'stroke-polyline',

          // color: 'black',
          fillOpacity: defaultFillOpacity,
          // color: 'red',
          // color: 'rgb(0,0,0)',
          // opacity: 0.1,
          opacity: 1,

          fillColor: 'hsl(240, 39%, 67%)', // 'hsl(240, 39%, 61%)',
          // fillColor: 'red',

          // weigh: 3,
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 7: // 400kV
        return {
          className: 'stroke-polyline',

          // color: 'black',
          opacity: 1,
          fillOpacity: defaultFillOpacity,

          fillColor: '#09A0E1',
          // fillColor: 'rgb(20, 20, 200)',

          // weight: 4,
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      case 9: // cc
        return {
          className: 'stroke-polyline',

          // color: 'black',
          fillOpacity: defaultFillOpacity,
          // color: 'rgba(0,0,0, 0.3)',
          // opacity: 0.1,

          fillColor: 'white',
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
      default:
        return {
          className: 'stroke-polyline',

          // color: 'black',
          fillOpacity: defaultFillOpacity,
          // color: 'rgba(0,0,0, 0.3)',
          // opacity: 0.9,

          fillColor: 'black',
          weight: defaultLineWeight,

          dashArray: lineDashing,
        };
    }
  };

  const pointToLayer = (geoJsonPoint, latlng): L.Layer => {
    // return L.circleMarker(latlng, undefined);
    return L.marker(latlng, { icon: subStationIcon });
  };

  const updateLayer = (): void => {
    const geojsonLayer = layerRef.current;
    if (geojsonLayer == null) return;

    if (!isLayerVisible) {
      // map.removeLayer(geojsonLayer);
      map.removeLayer(layerRef.current);
      return;
    }

    // modify geojsonData based on currentBounds
    if (geojsonData == null) {
      return;
    }

    geojsonLayer.clearLayers().addData(geojsonData);
    map.addLayer(geojsonLayer);
  };

  const checkVisibility = (): boolean => isLayerVisible;

  useEffect(() => {
    // if (!isLayerVisible) return;

    // Trigger update on map zoom or move events
    const cb = (): void => {
      console.log(
        'Callback Layer Visibility:',
        isLayerVisible,
        checkVisibility(),
      );
      if (!checkVisibility()) return;
      void fetchGeoJSONData();
    };
    // map.on('zoomend moveend', cb);

    cb();
    // return () => {
    //   // Clean up event listener on unmount
    //   map.off('zoomend moveend', cb);
    // };
  }, []);

  useEffect(() => {
    if (!isLayerVisible) return;
    updateLayer(); // Update layer on data or visibility changes

    // Trigger update on map zoom or move events
    map.on('zoomend moveend', fetchGeoJSONData as any);

    return () => {
      // Clean up event listener on unmount
      map.off('zoomend moveend', fetchGeoJSONData as any);
    };
  }, [geojsonData, isLayerVisible, map]);

  return (
    <LayersControl.Overlay name="Lignes RTE">
      <GeoJSON
        ref={layerRef}
        onEachFeature={onEachFeature}
        pointToLayer={pointToLayer}
        style={layerStyle}
        data={
          {
            type: 'FeatureCollection',
            features: [],
          } as any
        }
        eventHandlers={{
          add: (e) => {
            console.log('[Lines] Added Layer:', e.target);
            setLayerVisibility(true);
          },
          remove: (e) => {
            console.log('[Lines] Removed layer:', e.target);
            setLayerVisibility(false);
          },
        }}
      ></GeoJSON>
    </LayersControl.Overlay>
  );
};
