/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { LayersControl, useMap, GeoJSON } from 'react-leaflet';

import { api } from 'services/api';
// import { subStationIcon } from './substationIcon';
import { type BBOXdata } from './BBOXdata';

export const SubstationsLayer: React.FC = () => {
  const map = useMap();
  const [isLayerVisible, setLayerVisibility] = useState(true);
  const [geojsonData, setGeojsonData] = useState<
    GeoJSON.GeoJsonObject | undefined
  >(undefined);
  const [oldBbox, setOldBbox] = useState<BBOXdata>();
  const layerRef = useRef<any>();

  const fetchGeoJSONData = async (): Promise<void> => {
    if (!map) return;
    const currentBounds = map.getBounds();

    const currentBboxData = {
      lat: {
        min: currentBounds.getSouthWest().lat,
        max: currentBounds.getNorthEast().lat,
      },
      long: {
        min: currentBounds.getSouthWest().lng,
        max: currentBounds.getNorthEast().lng,
      },
    };

    const shouldFetch = (): boolean => {
      if (!oldBbox) return true;

      const newLatContained =
        oldBbox.lat.min < currentBboxData.lat.min &&
        currentBboxData.lat.max < oldBbox.lat.max;
      const newLongContained =
        oldBbox.long.min < currentBboxData.long.min &&
        currentBboxData.long.max < oldBbox.long.max;
      const newBboxContained = newLatContained && newLongContained;

      return !newBboxContained;
    };

    if (!shouldFetch()) {
      return;
    }

    setOldBbox(currentBboxData);

    const latMin = currentBboxData.lat.min.toFixed(2);
    const latMax = currentBboxData.lat.max.toFixed(2);

    const longMin = currentBboxData.long.min.toFixed(2);
    const longMax = currentBboxData.long.max.toFixed(2);

    const bbox = `long_min=${longMin}&lat_min=${latMin}&long_max=${longMax}&lat_max=${latMax}`;
    const url = `/map/substations?${bbox}`;

    try {
      const response = await api.get(url, {
        skipAuthRefresh: true,
      } as any);
      setGeojsonData(response.data);
    } catch (error) {
      console.error('Error fetching GeoJSON data:', error);
    }
  };

  const onEachFeature = (feature: any, layer: any): void => {
    // Create a tooltip but don't open it immediately
    const tooltip = L.tooltip({
      permanent: false,
      direction: 'top',
    });

    // Additional mouseover event to display more properties if needed
    layer.on('click mouseover mousemove', (e) => {
      //   const tooltipContent = `Name: ${lineId}<br> Tension: ${lineTension} <br>Line Type: ${
      //     feature?.properties.configuration as string
      //   } <br><br> ${JSON.stringify(feature?.properties)}`;

      const tooltipContent = Object.entries(feature?.properties ?? {})
        .map(([key, val]) => `${key} : ${val as string}`)
        .join('<br>');

      tooltip.setContent(tooltipContent);

      // Set tooltip position and open it
      tooltip.setLatLng(e.latlng);
      map.openTooltip(tooltip);
    });

    layer.on('mouseout', (e) => {
      map.closeTooltip(tooltip);
    });
  };

  const layerStyle = (feature: any): any => {
    switch (feature?.properties.tension_idr) {
      case 0: // hors tension
        return {
          color: 'black',
          fillColor: 'black',
        };
      case 1: // <45kV
        return {
          fillColor: 'rgb(80, 10,10)',
          color: 'rgb(80, 10,10)',
        };
      case 2: // 45kV
        return {
          fillColor: 'rgb(50, 50,150)',
          color: 'rgb(50, 50,150)',
        };
      case 3: // 63kV
        return {
          color: 'hsl(273, 38%, 61%)',
          fillColor: 'hsl(273, 38%, 61%)',
        };
      case 4: // 90kV
        return {
          color: 'hsl(273, 38%, 61%)',
          fillColor: 'hsl(273, 38%, 61%)',
        };
      case 5: // 150kV
        return {
          color: 'hsl(240, 39%, 61%)',
          fillColor: 'hsl(240, 39%, 61%)',
        };
      case 6: // 225kV
        return {
          color: 'hsl(240, 39%, 67%)',
          fillColor: 'hsl(240, 39%, 67%)',
        };
      case 7: // 400kV
        return {
          color: '#09A0E1',
          fillColor: '#09A0E1',
        };
      case 9: // cc
        return {
          color: 'white',
          fillColor: 'white',
        };
      default:
        return {
          color: 'black',
          fillColor: 'black',
        };
    }
  };

  const pointToLayer = (geoJsonPoint, latlng): any => {
    const radiusInMeters = 10;
    const tensionIdr = geoJsonPoint?.properties.tension_idr ?? 0;
    return L.circle(latlng, {
      radius: radiusInMeters * (1 + tensionIdr / 5 + Math.random() / 10),
    });
    // return L.circleMarker(latlng, { radius: 10 });
    // return L.marker(latlng, { icon: subStationIcon });
  };

  const updateLayer = (): void => {
    const geojsonLayer = layerRef.current;
    if (!geojsonLayer) return;

    // ... modify geojsonData based on currentBounds ...
    if (geojsonData) geojsonLayer.clearLayers().addData(geojsonData);

    // map.on('moveend zoomend', updateLayer);
    if (isLayerVisible) {
      map.addLayer(geojsonLayer);
    } else {
      map.removeLayer(geojsonLayer);
    }
  };

  //   const updateLayer = (): void => {
  //     if (!substationsLayerRef.current) return;
  //     // substationsLayerRef.current.
  //   };

  useEffect(() => {
    void fetchGeoJSONData(); // Fetch data on initial render
  }, []);

  // useEffect(() => {
  //   const geojsonLayer = layerRef.current;
  //   if (!map) return;

  //   let zoomStart = map.getZoom();
  //   let zoomEnd = map.getZoom();

  //   const cb1 = (e): void => {
  //     zoomStart = map.getZoom();
  //   };
  //   const cb2 = (e): void => {
  //     zoomEnd = map.getZoom();
  //     const zoomDiff = zoomEnd - zoomStart;
  //     if (zoomDiff === 0) return;

  //     // console.log(
  //     //   'zoom size [diff, start, end]',
  //     //   zoomDiff,
  //     //   zoomStart,
  //     //   zoomEnd,
  //     //   geojsonLayer,
  //     // );

  //     geojsonLayer.eachLayer((feature: any) => {
  //       const multiplier = 1.2 * Math.abs(zoomDiff);
  //       const oldRadius = feature.getRadius();
  //       const newRadius =
  //         zoomDiff > 0 ? oldRadius * multiplier : oldRadius / multiplier;

  //       feature.setRadius(newRadius);
  //     });
  //   };

  //   map.on('zoomstart', cb1);
  //   map.on('zoomend', cb2);

  //   return () => {
  //     map.off('zoomstart', cb1);
  //     map.off('zoomend', cb2);
  //   };
  // }, [map]);

  useEffect(() => {
    if (!isLayerVisible) return;

    updateLayer(); // Update layer on data or visibility changes

    // Trigger update on map zoom or move events
    map.on('zoomend moveend', fetchGeoJSONData as any);

    return () => {
      // Clean up event listener on unmount
      map.off('zoomend moveend', fetchGeoJSONData as any);
    };
  }, [geojsonData, isLayerVisible, map]);

  return (
    <LayersControl.Overlay name="Postes RTE">
      <GeoJSON
        ref={layerRef}
        onEachFeature={onEachFeature}
        pointToLayer={pointToLayer}
        style={layerStyle}
        data={
          {
            type: 'FeatureCollection',
            features: [],
          } as any
        }
        eventHandlers={{
          add: (e) => {
            // console.log('Added Layer:', e.target);
            setLayerVisibility(true);
          },
          remove: (e) => {
            // console.log('Removed layer:', e.target);
            setLayerVisibility(false);
          },
        }}
      ></GeoJSON>
    </LayersControl.Overlay>
  );
};
