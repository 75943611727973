export const roundWithPrecision = (precision: number) => (nbr: number) =>
  Math.round(nbr * Math.pow(10, precision)) / Math.pow(10, precision);

export function replaceAt<T>(array: T[], index: number, value: T): T[] {
  const newArr = array.slice(0);
  newArr[index] = value;
  return newArr;
}
export function removeAt<T>(array: T[], index: number): T[] {
  if (index >= array.length) return array;
  if (index < 0) return array;

  return [...array.slice(0, index), ...array.slice(index + 1)];
}
