import L from 'leaflet';
import React, { useContext, useRef } from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';
import { type IFiltersContext } from 'models/Context';
import { type IStrategyContext } from 'models/StrategyContext';
import { MapChild } from './MapChild';
import { Button, Collapse } from 'react-bootstrap';
import CustomMarker from './CustomMarker';
import { t } from 'i18next';
import { type FiltersContextType } from 'types';
import FiltersContext from 'context/FiltersContext';
import { MapLayersController } from 'components/quote/map/MapLayersController';
interface MapProps {
  filtersContext: IFiltersContext;
  strategyContext: IStrategyContext;
}

export const Map: React.FC<MapProps> = ({
  filtersContext,
  strategyContext,
}): React.ReactElement => {
  const mapRef = useRef<any>(null); // Use a ref to access the map instance
  // 7 Rue du Bac 15400 Riom-ès-Montagnes
  const { setFiltersContext } = useContext<FiltersContextType>(FiltersContext);

  const center = L.latLng(48.8588897, 2.320041);
  const handleClick = (): void => {
    setFiltersContext({
      ...filtersContext,
      openList: true,
    });
  };
  return (
    <>
      {filtersContext.openList === false && (
        <Button
          onClick={handleClick}
          aria-controls="example-collapse-text"
          variant="secondary"
          size="sm"
          className={`btn-display-strategy`}
        >
          <i className="icon icon-list_bulleted search-icon mx-1"></i>
          {t('strategy.content.display_list')}
        </Button>
      )}
      <MapContainer
        preferCanvas={true}
        center={center}
        zoom={14}
        scrollWheelZoom={true}
        minZoom={7}
        maxZoom={20}
        zoomControl={false}
        dragging={true}
        doubleClickZoom={true}
        ref={mapRef}
        trackResize={true}
      >
        <MapLayersController />
        <ZoomControl position="bottomleft" />
        {strategyContext.data.length > 0 ? (
          <MapChild
            strategyContext={strategyContext}
            filtersContext={filtersContext}
          />
        ) : filtersContext.coordinates?.lng !== 0 &&
          filtersContext.coordinates?.lat !== 0 &&
          !strategyContext.isLoading ? (
          <Collapse in={strategyContext.openMap}>
            <div id="collapse-carte">
              <CustomMarker
                lng={Number(filtersContext.coordinates?.lng)}
                lat={Number(filtersContext.coordinates?.lat)}
                label={filtersContext.coordinates?.label}
                position={true}
              />
            </div>
          </Collapse>
        ) : null}
      </MapContainer>
    </>
  );
};

export default Map;
