import { getSnappedMultiPointPath } from 'services/quotes';
import { type t1 } from './types';
import { LatLng } from 'leaflet';

export const snapToRoadHandler = (newPath: t1) => () => {
  if (newPath.current == null) return;

  const newLineExport = newPath.current.toGeoJSON();
  // console.log('Exported New Path:', newLineExport); // TODO: do a backend API call here.

  // TODO: add that there are at least 3 points in the array
  void getSnappedMultiPointPath(newLineExport.geometry.coordinates).then(
    (res) => {
      console.log('multi point road snapping', res.data);
      if (newPath.current == null) return;
      const newLeafletPoints = res.data.map(
        ({ long, lat }) => new LatLng(lat, long),
      );

      newPath.current.setLatLngs(newLeafletPoints);

      newPath.current.toggleEdit();
      newPath.current.toggleEdit();
    },
  );
};
