/* eslint-disable @typescript-eslint/no-unused-vars */
import type L from 'leaflet';
import React, { useEffect, useState } from 'react';
import { FeatureGroup, Polyline, Popup, useMap } from 'react-leaflet';
import {
  type IPointCoords,
  type AlternativePaths,
  type IStrategyQuote,
} from 'models/Quote';
import { useDeviceType } from 'shared/useDeviceType';
import QuoteMarkerTrace from './QuoteMarkerTrace';
import { LatLng, type LatLngBoundsExpression } from 'leaflet';
import QuoteMarker from './QuoteMarker';
import { TraceTypeLabels } from 'enums';
import { simplify } from '@turf/turf';

interface MapQuoteTraceChildProps {
  strategy: IStrategyQuote;
  isLoading: boolean;
  pathSelected: string;
  pointCoords: IPointCoords;
}

function ArrayReplace<T>(array: T[], index: number, newValue: T): T[] {
  return [...array.slice(0, index), newValue, ...array.slice(index + 1)];
}

export const MapQuoteTraceChild: React.FC<MapQuoteTraceChildProps> = ({
  strategy,
  isLoading,
  pathSelected,
  pointCoords,
}): React.ReactElement => {
  const defaultPolylineWeight = 12;
  const focusPolylineWeight = 20;

  const [polylineWeight, setPolylineWeight] = useState<number[]>(
    strategy.alternativePaths.map(() => defaultPolylineWeight),
  );
  const polylineListRef = React.useRef<L.Polyline[]>([]);
  const { isDesktop } = useDeviceType();
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
    let result: any = [];
    const v = strategy.alternativePaths.map((item) => {
      result = [...result, ...item.polylineCoords];
      return item.polylineCoords;
    });
    map.fitBounds(result as LatLngBoundsExpression);
  }, [strategy]);

  return (
    <>
      {strategy.alternativePaths.map(
        (alternative: AlternativePaths, index: number) => {
          const isActive = pathSelected === alternative.uid;
          const middleAlter =
            alternative.polylineCoords[
              Math.floor((alternative.polylineCoords.length - 1) / 2)
            ];
          const pointCoordsAlter = Object.values(middleAlter);

          const getIdStrategy = (index: number): string => {
            const customLabel = `TRACE${Number(index + 1)}`;
            return TraceTypeLabels[customLabel as keyof typeof TraceTypeLabels];
          };

          return (
            <FeatureGroup key={index}>
              <QuoteMarker
                lng={Number(pointCoords?.lng)}
                lat={Number(pointCoords?.lat)}
                label={pointCoords?.label}
                position={true}
              />
              <Polyline
                pathOptions={{
                  opacity: 1,
                  weight: polylineWeight[index],
                  stroke: true,
                  color: isActive ? '#00B0FF' : '#929497',
                  className: 'shadow-box',
                }}
                positions={alternative.polylineCoords}
                weight={polylineWeight[index]}
                eventHandlers={{
                  mouseover: (e: L.LeafletMouseEvent) => {
                    setPolylineWeight(
                      ArrayReplace(polylineWeight, index, focusPolylineWeight),
                    );
                    e.target.openPopup();
                  },
                  mouseout: (e: L.LeafletMouseEvent) => {
                    setPolylineWeight(
                      ArrayReplace(
                        polylineWeight,
                        index,
                        defaultPolylineWeight,
                      ),
                    );
                    e.target.closePopup();
                  },
                }}
                ref={(element) => {
                  if (element == null) return;
                  polylineListRef.current[index] = element;
                }}
              >
                <QuoteMarkerTrace
                  label={strategy.pointCoords.label}
                  lng={Number(pointCoordsAlter[1])}
                  lat={Number(pointCoordsAlter[0])}
                  id={index}
                  idTrace={getIdStrategy(index)}
                  position={false}
                  isLoading={isLoading}
                  point={strategy?.point}
                  targetType={strategy?.targetType}
                />
                {/* {isDesktop && (
                  <Popup closeButton={false}>
                    <PointPopup
                      lng={Number(pointCoordsAlter[1])}
                      lat={Number(pointCoordsAlter[0])}
                      point={strategy?.point}
                      targetType={strategy?.targetType}
                    />
                  </Popup>
                )} */}
              </Polyline>
            </FeatureGroup>
          );
        },
      )}
    </>
  );
};

export default MapQuoteTraceChildProps;
