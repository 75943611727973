/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type t0, type t1 } from './types';

export const undoHandler =
  (
    map: t0,
    newPath: t1,
    actionBuffer: any,
    undoCounter: any,
    setUndoCounter: any,
  ) =>
  () => {
    if (map == null) {
      console.log('[Undo handler] map obj not found');

      return;
    }
    if (!actionBuffer.current.length) {
      console.log('[Undo handler] action buffer empty');

      return;
    }
    // if (undoCounter > 0) return;
    if (newPath.current == null) {
      console.log('[Undo handler] new path obj not found');
      return;
    }

    const actionData =
      actionBuffer.current[actionBuffer.current.length - 1 - undoCounter - 1];
    console.log(`[Undo handler] counter ${undoCounter} actionData`, actionData);
    setUndoCounter(undoCounter + 1);
    newPath.current.setLatLngs(actionData.data);
    newPath.current.toggleEdit();
    newPath.current.toggleEdit();
  };

export const redoHandler =
  (
    map: t0,
    newPath: t1,
    actionBuffer: any,
    undoCounter: any,
    setUndoCounter: any,
  ) =>
  () => {
    if (map == null) {
      console.log('[Undo handler] map obj not found');

      return;
    }
    if (!actionBuffer.current.length) {
      console.log('[Undo handler] action buffer empty');

      return;
    }
    // if (undoCounter > 0) return;
    if (newPath.current == null) {
      console.log('[Undo handler] new path obj not found');
      return;
    }

    // if (undoCounter > 0) return;

    const actionData =
      actionBuffer.current[actionBuffer.current.length - 1 - undoCounter - 0];
    console.log('[Redo handler] actionData', actionData);

    if (!actionData) return;

    setUndoCounter(undoCounter - 1);
    newPath.current.setLatLngs(actionData.data);
    newPath.current.toggleEdit();
    newPath.current.toggleEdit();
  };
