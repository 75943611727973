/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import { TileLayer, LayersControl, WMSTileLayer } from 'react-leaflet';

import {
  MAPTILER_ACCESS_KEY,
  MAP_VECTOR_STYLE,
  MAP_URL,
  MAP_MAX_ZOOM,
  MAP_NATIVE_MAX_ZOOM,
  MAP_KEEP_BUFFER,
} from 'utils/constants';
import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import { ElectricLinesLayer } from './ElectricLinesLayer';
import { SubstationsLayer } from './SubstationsLayer';

export const MapLayersController: React.FC = (): React.ReactElement => {
  return (
    <LayersControl collapsed={true} position="bottomright">
      <LayersControl.BaseLayer name={'OSM - Default Style'} checked={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={MAP_URL}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'OSM - HOT style'}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={`https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png`}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'OSM - Maptiler Vector - Custom Style'}>
        <VectorTileLayer
          styleUrl={MAP_VECTOR_STYLE}
          accessToken={MAPTILER_ACCESS_KEY}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'OSM - Maptiler Vector - Backdrop Style'}>
        <VectorTileLayer
          accessToken={MAPTILER_ACCESS_KEY}
          styleUrl={`https://api.maptiler.com/maps/backdrop/style.json?key=${MAPTILER_ACCESS_KEY}`}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'OSM - Maptiler Vector - Dark Style'}>
        <VectorTileLayer
          accessToken={MAPTILER_ACCESS_KEY}
          styleUrl={
            `https://api.maptiler.com/maps/basic-v2-dark/style.json?key=${MAPTILER_ACCESS_KEY}`
            // 'https://raw.githubusercontent.com/openmaptiles/dark-matter-gl-style/master/style.json'
          }
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer
        // name={'OSM - Maptiler Vector - Positron Style'}
        name={'OSM - Maptiler Vector - Light Style'}
      >
        <VectorTileLayer
          accessToken={MAPTILER_ACCESS_KEY}
          styleUrl={
            `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${MAPTILER_ACCESS_KEY}`
            // 'https://raw.githubusercontent.com/openmaptiles/positron-gl-style/master/style.json'
          }
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'Satellite - GeoData HD'}>
        <WMSTileLayer
          layers="ORTHOIMAGERY.ORTHOPHOTOS"
          version="1.3.0"
          url={`https://data.geopf.fr/wms-r?VERSION=1.3.0`}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'Satellite - ESRI'}>
        <TileLayer
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'Satellite - Maptiler'}>
        <TileLayer
          attribution={`<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>`}
          url={`https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=${MAPTILER_ACCESS_KEY}`}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name={'Satellite - Google'}>
        <TileLayer
          attribution="Google Maps"
          url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}`}
          maxNativeZoom={MAP_NATIVE_MAX_ZOOM}
          maxZoom={MAP_MAX_ZOOM}
          keepBuffer={MAP_KEEP_BUFFER}
        />
      </LayersControl.BaseLayer>

      <SubstationsLayer />
      <ElectricLinesLayer />
    </LayersControl>
  );
};
