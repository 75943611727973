/* eslint-disable @typescript-eslint/prefer-optional-chain */
import L from 'leaflet';
import React from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';
import {
  type AlternativePaths,
  type IPointCoords,
  type IStrategyQuote,
} from 'models/Quote';
import { MapQuoteStrategyChild } from './MapQuoteStrategyChild';
import { MapLayersController } from './MapLayersController';

interface QuoteStrategyProps {
  pointCoords: IPointCoords;
  strategy: IStrategyQuote;
  pathSelected?: AlternativePaths;
}

export const MapQuoteStrategy: React.FC<QuoteStrategyProps> = ({
  pointCoords,
  strategy,
  pathSelected,
}): React.ReactElement => {
  const center = L.latLng(strategy.pointCoords?.lat, strategy.pointCoords?.lng);
  const zoom = 16;

  return (
    <>
      {strategy !== undefined && (
        <MapContainer
          preferCanvas={true}
          center={center}
          zoom={zoom}
          scrollWheelZoom={true}
          minZoom={7}
          maxZoom={20}
          zoomControl={false}
          dragging={true}
          doubleClickZoom={true}
          id={`strategy-${strategy.id}`}
        >
          <MapLayersController />
          <ZoomControl position="bottomleft" />
          <div id="collapse-carte">
            {pathSelected != null && (
              <MapQuoteStrategyChild
                strategy={strategy}
                pointCoords={pointCoords}
                pathSelected={pathSelected}
              />
            )}
          </div>
        </MapContainer>
      )}
    </>
  );
};

export default MapQuoteStrategy;
