import L from 'leaflet';
import marker from '../../../assets/y-substation.svg';
// import markerIconPng from 'leaflet/dist/images/marker-icon.png';

// import styled from 'styled-components';
// const subsStationMarkerIcon = styled(() => (
//   <DivIcon>
//     <div
//       className="custom-div-icon"
//       style="background-color:#c30b82;"
//       className="marker-pin"
//     ></div>
//     <i className="material-icons">weekend</i>
//   </DivIcon>
// ))`
//   .marker-pin {
//     width: 30px;
//     height: 30px;
//     border-radius: 50% 50% 50% 0;
//     background: #c30b82;
//     position: absolute;
//     transform: rotate(-45deg);
//     left: 50%;
//     top: 50%;
//     margin: -15px 0 0 -15px;
//   }
//   // to draw white circle
//   .marker-pin::after {
//     content: '';
//     width: 24px;
//     height: 24px;
//     margin: 3px 0 0 3px;
//     background: #fff;
//     position: absolute;
//     border-radius: 50%;
//   }

//   // to align icon
//   .custom-div-icon i {
//     position: absolute;
//     width: 22px;
//     font-size: 22px;
//     left: 0;
//     right: 0;
//     margin: 10px auto;
//     text-align: center;
//   }
// `;

export const subStationIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  //   iconUrl: markerIconPng,
  popupAnchor: [-0, -0],
  iconSize: [20, 20],
});
