/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import 'leaflet-editable';
import { type LatLng } from 'leaflet';
import { type t0, type t1, type t2 } from './types';

export const selectHandler =
  (map: t0, newPath: t1, setPointsSelected: t2) => (e) => {
    if (map == null) return;

    // if (dragModeEnabled) return;
    console.log('select tool function entered.');

    const selectionRectangle = map.editTools.startRectangle();

    const selectionEndCb = (e): void => {
      console.log('selection, editable:drawing:end', selectionRectangle);
      if (!selectionRectangle) return;
      const bbox = selectionRectangle.getBounds();
      // selectionRectangle.off('editable:drawing:end', selectionEndCb);

      if (Object.keys(bbox).length === 0) return;

      // console.log('selection bbox:', bbox, bbox.toBBoxString());
      // console.log('selection bbox:', Object.keys(bbox));

      if (newPath.current == null) return;

      const ll = newPath.current.getLatLngs() as LatLng[];
      const selectedPoints = ll
        .map((point, index) => ({ index, point }))
        .filter(({ point }) => bbox.contains(point as any));

      setPointsSelected(selectedPoints);

      selectionRectangle?.remove();

      // newPath.redraw();
      newPath.current.toggleEdit();
      newPath.current.toggleEdit();
      selectedPoints?.forEach(({ point }) => {
        // console.log('point to select:', point);
        (point as any).__vertex._icon.classList.add('selected-vertex');
      });

      // map.on('keypress', (e) => {
      //   console.log('keypressed', e.originalEvent.key);

      //   if (e.originalEvent.key === 'd') {
      //     if (!map) {
      //       console.log('delete keyboard shortcut pressed. map not found');
      //       return;
      //     }
      //     const idxs = selectedPoints.map((obj) => obj.index);
      //     const newPoints = ll.filter((p, i) => !idxs.includes(i));
      //     newPath.setLatLngs(newPoints);
      //     newPath.toggleEdit();
      //     newPath.toggleEdit();
      //   }
      // });
    };

    selectionRectangle?.on('editable:shape:new', () => {
      console.log('new shape of selection rectangle');
    });
    selectionRectangle?.on('editable:drawing:end', selectionEndCb);
  };
