import 'leaflet-editable';
import { type LeafletKeyboardEvent } from 'leaflet';
import { type t1, type t0 } from './types';
import { redoHandler, undoHandler } from './undoRedo';
import { deleteSelectedPoints, deselectPoints } from './deletePoints';

// TODO: this event listener is added to the map on each component re-render. put this in a useEffect or outside the component.
export const keyboardShortcutsHandler =
  (
    map: t0,
    isEditingPath: boolean,
    isConfirmingNewPath: boolean,
    dragModeEnabled: boolean,
    selectHandlerMemo: any,
    setDragMode: any,
    newPath: t1,
    pointsSelected: any,
    setPointsSelected: any,
    actionBuffer: any,
    undoCounter: any,
    setUndoCounter: any,
  ) =>
  (e: LeafletKeyboardEvent) => {
    const ee = e.originalEvent;
    console.log('key event', ee.code);

    if (ee.code === 'Space') {
      if (map == null) return;

      // this behavior should only work when editing a path and that it's not in the confirmation stage.
      if (!isEditingPath) return;
      if (!(isEditingPath && !isConfirmingNewPath)) return;

      const newDragState = !dragModeEnabled;
      console.log('drag Mode Enabled:', dragModeEnabled);

      if (dragModeEnabled) {
        map.dragging.disable();
        map.addEventListener('mousedown', selectHandlerMemo);
      } else {
        map.dragging.enable();
        map.removeEventListener('mousedown', selectHandlerMemo);
      }

      setDragMode(newDragState);
      return;
    }

    if (ee.code === 'Backspace' || ee.code === 'Delete') {
      deleteSelectedPoints(
        map,
        newPath,
        pointsSelected,
        setPointsSelected,
        actionBuffer,
      )();
    } else if (ee.code === 'Escape') {
      deselectPoints(newPath, setPointsSelected)();
    }

    if (!(ee.code === 'KeyZ')) return;
    if (ee.shiftKey) {
      redoHandler(map, newPath, actionBuffer, undoCounter, setUndoCounter)();
      return;
    }
    undoHandler(map, newPath, actionBuffer, undoCounter, setUndoCounter)();
  };
