/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Tab, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'shared/Icon';
import StrategyQuote from './StrategyQuote';
import { useTranslation } from 'react-i18next';

import DetailsCosts from './DetailsCosts';
import {
  type IStrategyQuote,
  type IQuote,
  type AlternativePaths,
  type IPointCoords,
} from 'models/Quote';
import MapQuoteStrategy from '../map/MapQuoteStrategy';
import { getDetailsQuote } from 'services/quotes';
import { Loading } from 'shared/Loading';
import DiscardedStrategy from './DiscardedStrategy';
import CommentaryStrategy from './CommentaryStrategy';
import DetailsTrace from './DetailsTrace';
import DeleteStrategyModal from './DeleteStrategyModal';

import { useDeviceType } from 'shared/useDeviceType';
import { showErrorNotification } from 'services/NotificationService';

const StyledContainer = styled(Container)`
  .sticky-header {
    height: 4rem;
  }
  .sticky-map {
    padding-top: 0;
    height: calc(100vh - 4rem);
  }
  .btn-link {
    --bs-btn-padding-y: 2px;
    --bs-btn-padding-x: 2px;
    &:hover,
    &.active {
      background-color: transparent;
      border-color: transparent;
      color: rgba(0, 169, 227, 1);
    }

    &.border {
      border-radius: 4px;
      border: 1px solid rgba(0, 127, 182, 1) !important;
      text-decoration: none;
      padding-bottom: 0;
      display: flex;

      > .icon {
        font-size: 2rem;
        color: rgba(0, 127, 182, 1);
      }
    }
  }

  .tab-comment {
    position: relative;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    z-index: 0;
    .form-label {
      color: #009cdf;
      font-size: 10px;
      line-height: 24px;
      z-index: 99;
      position: absolute;
      top: -12px;
      background: #fff;
      padding: 0 5px;
      left: 10px;
    }
    .form-control {
      color: var(--black-087, rgba(0, 0, 0, 0.87));
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      border: 1px solid #009cdf;
    }
  }

  .collapse:not(.show) {
    display: none;
  }

  .accordion-collapse {
    width: 100%;
  }

  .accordion-item {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    .accordion-button {
      width: 100%;
      padding: 5px;
      font-size: 0.8rem;
      padding-right: 0;

      &:not(.collapsed) {
        color: var(--bs-accordion-btn-color);
        background-color: transparent;
        box-shadow: none;

        &:after {
          background-image: var(--bs-accordion-btn-icon);
        }
      }

      &:after {
        margin-left: 1rem;
        position: relative;
        bottom: 0.8rem;
        right: 0;
        --bs-accordion-active-color: #212529;
      }
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    .accordion-body,
    .accordion-header {
      padding: 0;
      width: 100%;

      h3,
      .h3 {
        color: #000;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px; /* 140% */
        text-transform: uppercase;
        margin-bottom: 0;

        @media (max-width: 767.98px) {
          font-size: 18px;
          padding: 1rem 0 0 0;
        }
      }

      h5 {
        color: #000;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 0;

        &.uppercase {
          text-transform: uppercase;
        }

        @media (max-width: 575.98px) {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 0;
        }
      }

      P {
        color: #000;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .badge.bg-primary {
        border-radius: 5px;
        background-color: rgba(0, 156, 223, 0.1) !important;
        color: #000;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
      }

      .fw-bolder strong {
        font-size: 16px;
      }
    }
  }
  .discarded {
    color: rgba(255, 153, 0, 1);
  }
  .commentary {
    background-color: rgb(0, 169, 227, 0.2);
    padding: 0.8rem;

    h6 {
      font-weight: 600;
    }
    p {
      font-size: 13px;
    }
  }
  .icon-arrow {
    font-size: 0.6rem;
    margin: 0 0.5rem;
    color: #09a0e1;
    position: relative;
    top: -2px;
  }

  .isDisplay {
    display: block;
  }
  .notDisplay {
    display: none;
  }
`;

const StrategyQuoteDetails: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { quoteId, idStrategy } = useParams<{
    quoteId: string;
    idStrategy: string;
  }>();

  const locationData = useLocation();
  const quoteUid = locationData?.state?.quoteUid ?? quoteId;
  if (!quoteUid) navigate('/quote');

  const { isMobile, isTablet } = useDeviceType();
  const isDesktop = !isMobile && !isTablet;

  const [detailsQuote, setDetailsQuote] = useState<IQuote>();
  const [detailsStrategy, setDetailsStrategy] = useState<IStrategyQuote>();
  const [pathSelected, setPathSelected] = useState<AlternativePaths>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [openQuote, setOpenQuote] = useState<boolean>(false);
  // control textarea form
  const [showComment, setShowComment] = useState(false);

  const pointCoords: IPointCoords = {
    lat: Number(detailsQuote?.lat),
    lng: Number(detailsQuote?.long),
    label: detailsQuote?.address,
  };

  const getPathSelected = (detailsStrategy: IStrategyQuote) => {
    // SELECTED ALTERNATIVE PATH
    const path = detailsStrategy?.alternativePaths.find(
      (item: AlternativePaths) => item.uid == detailsStrategy?.selectedPath,
    );
    setPathSelected(path);
  };

  const getStrategy = (quote: IQuote, idStrategy: string): void => {
    const strategy = quote.strategies.filter(
      (item: IStrategyQuote) => item.id == idStrategy,
    )[0];

    if (!strategy) {
      navigate(`/quote/${quote.id}`, { state: { quoteUid: quote.uid } });
    }

    setDetailsStrategy(strategy);
    getPathSelected(strategy);
  };

  const getStrategyQuote = (quoteUid: string, idStrat: string) => {
    setLoading(true);

    void getDetailsQuote(quoteUid).then((response) => {
      const { data: quote, status } = response;

      if (status === 404) {
        showErrorNotification(t('quote.details.errors.404'));
        navigate('/quote');
      }

      setDetailsQuote(quote);
      getStrategy(quote, idStrat);
      setLoading(false);
    });

    setShowComment(false);
  };

  useEffect(() => {
    if (quoteUid !== undefined && idStrategy !== undefined) {
      getStrategyQuote(quoteUid, idStrategy);
    }
  }, []);

  const handleShowComment = (): void => {
    setShowComment(!showComment);
  };

  // CONTROL NAVIGATION TABS
  const [activeTab, setActiveTab] = useState<string>(`strategy-${idStrategy}`);

  const handleTabClick = (
    quoteId: string,
    quoteUid: string,
    startId: string,
  ) => {
    if (
      quoteId !== undefined &&
      quoteUid !== undefined &&
      startId !== undefined
    ) {
      navigate(`/quote/${quoteId}/strategy/${startId}`, {
        state: { quoteUid },
      });

      setActiveTab(`strategy-${startId}`);
      getStrategyQuote(quoteUid, startId);
    }
  };

  const handleShowReceived = (show: boolean) => {
    setShowComment(show);
  };

  // CONTROLE MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getTensionNbr = (tension: number) => {
    return Math.round(tension / 1000);
  };

  // Custom comparison function
  const compareItems = (a: IStrategyQuote, b: IStrategyQuote) => {
    if (a.discarded === false && b.discarded === false) {
      return 0; // Leave the order unchanged
    } else if (a.discarded === false) {
      return -1; // a should come before b
    } else {
      return 1; // a should come after b
    }
  };

  const handleUpdateCosts = () => {
    idStrategy && quoteUid && getStrategyQuote(quoteUid, idStrategy);
  };

  return (
    <>
      {detailsQuote !== undefined && !isLoading ? (
        <>
          <StyledContainer fluid>
            {!isDesktop && (
              <Button
                onClick={() => {
                  setOpenQuote(!openQuote);
                }}
                aria-controls="example-collapse-text"
                aria-expanded={openQuote}
                variant="secondary"
                size="sm"
                className={`btn-display-strategy d-lg-none`}
              >
                <i className="icon icon-list_bulleted search-icon mx-1"></i>
                {openQuote
                  ? t('strategy.content.display_map')
                  : t('strategy.content.display_list')}
              </Button>
            )}

            <Row className={'quote'}>
              <Col
                md="6"
                lg="6"
                xxl="5"
                className={`quote_details_strategy ${
                  isDesktop || (!isDesktop && openQuote)
                    ? 'isDisplay'
                    : 'notDisplay'
                }`}
              >
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={activeTab}
                >
                  <Row className="">
                    <Col sm={12} md={1} className="details_nav">
                      <div className="nav_icon">
                        <Icon
                          iconName="ArrowLeftSquare"
                          color="rgba(0, 156, 223, 1)"
                          className="back-icon"
                          onClick={() => {
                            navigate(`/quote/${quoteId}`, {
                              state: { quoteUid },
                            });
                          }}
                        />
                      </div>
                      <div
                        className={`nav nav-pills ${
                          isDesktop ? 'flex-column' : 'flex-row'
                        }`}
                      >
                        {detailsQuote?.strategies
                          .sort(compareItems)
                          .map((strategy: IStrategyQuote) => {
                            return (
                              <a
                                key={strategy.id}
                                className={`nav-link ${
                                  activeTab === `strategy-${strategy.id}`
                                    ? 'active'
                                    : ''
                                } ${strategy.discarded ? 'discarded' : ''}`}
                                id={strategy.id}
                                onClick={() => {
                                  handleTabClick(
                                    detailsQuote.id,
                                    detailsQuote.uid,
                                    strategy.id,
                                  );
                                }}
                              >
                                {`S${strategy.id}`}
                              </a>
                            );
                          })}
                      </div>
                    </Col>
                    <Col sm={12} md={11}>
                      <div className="tab-content">
                        {detailsQuote.strategies.map(
                          (strategy: IStrategyQuote, index) => {
                            return (
                              <div
                                key={index}
                                className={`tab-pane fade ${
                                  activeTab === `strategy-${strategy.id}`
                                    ? 'show active'
                                    : ''
                                }`}
                              >
                                {isDesktop ? (
                                  <Row className="tab-header border-bottom">
                                    <Col
                                      md="7"
                                      className="d-flex justify-content-between align-items-center"
                                    >
                                      {quoteId &&
                                        detailsQuote?.uid &&
                                        idStrategy && (
                                          <DiscardedStrategy
                                            strategy={detailsStrategy}
                                            quoteUid={detailsQuote?.uid}
                                            quoteId={quoteId}
                                            idStrategy={idStrategy}
                                            isDesktop={true}
                                          />
                                        )}
                                    </Col>
                                    <Col
                                      md="5"
                                      className="d-flex justify-content-end align-items-center"
                                    >
                                      <Button
                                        className={`btn-link me-3 ${
                                          showComment ? 'active' : ''
                                        }`}
                                        onClick={handleShowComment}
                                      >
                                        <Icon
                                          iconName={`${
                                            showComment
                                              ? 'ChatLeftFill'
                                              : 'ChatLeft'
                                          }`}
                                          color={`${
                                            showComment
                                              ? 'rgba(0, 169, 227, 1)'
                                              : 'rgba(28, 27, 31, 1)'
                                          }`}
                                          className="icon"
                                        />{' '}
                                      </Button>
                                      <Button className="btn-link">
                                        <Icon
                                          iconName="Trash3"
                                          color="rgba(255, 0, 0, 1)"
                                          className="icon"
                                          onClick={openModal}
                                        />{' '}
                                      </Button>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row className="tab-header border-bottom">
                                    <Col
                                      md="7"
                                      className="d-flex justify-content-between align-items-center"
                                    >
                                      <h4 className="me-2">
                                        <strong
                                          className={
                                            detailsStrategy?.discarded !==
                                              undefined &&
                                            detailsStrategy.discarded
                                              ? 'discarded'
                                              : ''
                                          }
                                        >{`${t(
                                          'quote.details.strategy_quote.discarded_strategy.strategy',
                                        )} ${strategy?.id}`}</strong>{' '}
                                      </h4>

                                      <div className="d-flex justify-content-end align-items-center">
                                        <Button
                                          className={`btn-link me-3 ${
                                            showComment ? 'active' : ''
                                          }`}
                                          onClick={handleShowComment}
                                        >
                                          <Icon
                                            iconName={`${
                                              showComment
                                                ? 'ChatLeftFill'
                                                : 'ChatLeft'
                                            }`}
                                            color={`${
                                              showComment
                                                ? 'rgba(0, 169, 227, 1)'
                                                : 'rgba(28, 27, 31, 1)'
                                            }`}
                                            className="icon"
                                          />{' '}
                                        </Button>
                                        <Button className="btn-link">
                                          <Icon
                                            iconName="Trash3"
                                            color="rgba(255, 0, 0, 1)"
                                            className="icon"
                                            onClick={openModal}
                                          />{' '}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col
                                      md="5"
                                      className="d-flex justify-content-start align-items-center"
                                    >
                                      {quoteId &&
                                        detailsQuote?.uid &&
                                        idStrategy && (
                                          <DiscardedStrategy
                                            strategy={detailsStrategy}
                                            quoteUid={detailsQuote?.uid}
                                            quoteId={quoteId}
                                            idStrategy={idStrategy}
                                            isDesktop={false}
                                          />
                                        )}
                                    </Col>
                                  </Row>
                                )}

                                {quoteId && detailsQuote?.uid && idStrategy && (
                                  <CommentaryStrategy
                                    strategy={detailsStrategy}
                                    quoteUid={detailsQuote?.uid}
                                    quoteId={quoteId}
                                    idStrategy={idStrategy}
                                    showComment={showComment}
                                    showCommentary={handleShowReceived}
                                  />
                                )}

                                {detailsStrategy?.id !== undefined && (
                                  <Row className="mt-4 border-bottom pb-3">
                                    <Col>
                                      <StrategyQuote
                                        point={detailsStrategy?.point}
                                        targetType={detailsStrategy?.targetType}
                                        lineType={detailsStrategy?.lineType}
                                        id={detailsStrategy?.id}
                                        isReady={strategy?.isReady}
                                        tension={detailsStrategy?.tension_nbr}
                                        displayTitle={false}
                                        temperatureAlert={
                                          detailsStrategy.temperature_alert
                                        }
                                      />
                                    </Col>
                                  </Row>
                                )}

                                <Row className="mt-4 border-bottom pb-3">
                                  <Col>
                                    {/* Accordion TRACE */}
                                    {pathSelected !== undefined &&
                                      detailsStrategy !== undefined &&
                                      quoteId &&
                                      quoteUid &&
                                      idStrategy && (
                                        <DetailsTrace
                                          detailsStrategy={detailsStrategy}
                                          pathSelected={pathSelected}
                                          quoteId={quoteId}
                                          quoteUid={quoteUid}
                                          idStrategy={idStrategy}
                                        />
                                      )}

                                    {/* Accordion ESTIMATED COST */}
                                    {detailsStrategy?.targetType != null &&
                                      quoteId &&
                                      idStrategy &&
                                      detailsQuote?.uid && (
                                        <DetailsCosts
                                          detailsStrategy={detailsStrategy}
                                          quoteUid={detailsQuote?.uid}
                                          quoteId={detailsQuote.id}
                                          idStrategy={idStrategy}
                                          tensionNbr={
                                            Number(detailsStrategy.tension_nbr)
                                              ? getTensionNbr(
                                                  Number(
                                                    detailsStrategy.tension_nbr,
                                                  ),
                                                )
                                              : 0
                                          }
                                          handleUpdate={handleUpdateCosts}
                                        />
                                      )}
                                  </Col>
                                </Row>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
              <Col md="12" lg="6" xxl="7" className={`map`}>
                {detailsStrategy !== undefined &&
                  pathSelected !== undefined && (
                    <MapQuoteStrategy
                      pointCoords={pointCoords}
                      strategy={detailsStrategy}
                      pathSelected={pathSelected}
                    />
                  )}
              </Col>
            </Row>
          </StyledContainer>

          {/* MODAL DELETE STRATEGY QUOTE        */}
          {detailsStrategy && detailsQuote?.uid && detailsQuote?.id && (
            <DeleteStrategyModal
              isOpen={isModalOpen}
              onClose={closeModal}
              quoteUid={detailsQuote?.uid}
              quoteId={detailsQuote?.id}
              detailsStrategy={detailsStrategy}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default StrategyQuoteDetails;
