/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import UpdateCostsModal from './UpdateCostsModal';
import BadgeEstimatedCost from './BadgeEstimatedCost';
import { type IStrategyQuote, type Piquage } from 'models/Quote';
import { getCostValueCommas } from 'utils';
import QuoteContext from 'context/QuoteContext';
import { type QuoteContextType } from 'types';
// import { useNavigate } from 'react-router';

const StyledForm = styled(Form)`
  .list-group {
    --bs-list-group-border-color: transparent;
    --bs-list-group-border-width: 0;

    .list-group-item {
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      @media (max-width: 575.98px) {
        font-size: 0.8rem;
      }

      .badge.bg-primary {
        font-size: 16px;
      }

      .icon-arrow {
        font-size: 0.6rem;
        margin: 0 0.5rem;
        color: #09a0e1;
        position: relative;
        top: 2px;
      }

      i {
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #009cdf;
      }
    }
  }
  .btn-update {
    position: absolute;
    right: -1.5rem;
    top: -4px;
    text-decoration: none;
    color: #000;
    .icon {
      font-size: 1.5rem;
    }

    @media (max-width: 575.98px) {
      right: -1.3rem;
    }

    &:disabled,
    &.disabled {
      color: var(--bs-btn-disabled-color);
      pointer-events: none;
      background-color: transparent;
      border-color: var(--bs-btn-disabled-border-color);
      opacity: var(--bs-btn-disabled-opacity);
    }
  }

  .btn-update2 {
    --bs-btn-color: rgb(9, 160, 225);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: rgb(9, 160, 225);
    --bs-btn-hover-color: rgb(9, 160, 225);
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: rgb(9, 160, 225);
    --bs-btn-active-color: rgb(9, 160, 225);
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: rgb(9, 160, 225);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: rgb(9, 160, 225);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: rgb(9, 160, 225);
    border-radius: 0.3rem;

    .icon {
      font-size: 1rem;
      position: relative;
      top: 2px;
    }
  }
`;

interface QuoteDescriptionProps {
  tensionNbr: number;
  detailsStrategy: IStrategyQuote;
  quoteUid: string;
  quoteId: string;
  idStrategy: string;
  handleUpdate: () => void;
}

const DetailsCosts: React.FC<QuoteDescriptionProps> = ({
  detailsStrategy,
  tensionNbr,
  quoteUid,
  quoteId,
  idStrategy,
  handleUpdate,
}): React.ReactElement => {
  const { t } = useTranslation();
  const {
    costsDetails,
    nbr_busbar,
    poste_type,
    targetType,
    poste_facteur_contrainte,
  } = detailsStrategy;

  const { quoteContext, setQuoteContext } =
    useContext<QuoteContextType>(QuoteContext);
  const getCostsByKey = (key: string): number => {
    return key && targetType === 'SUBSTATION'
      ? costsDetails.poste[key]
      : costsDetails.piquage[key];
  };
  useEffect(() => {
    setQuoteContext({
      ...quoteContext,
      totalSeche: getCostsByKey('seche'),
    });
  }, [getCostsByKey('seche')]);

  const typeWorks = (): string => {
    return `Total Dépense Sèche (Coût de travaux ${
      targetType === 'SUBSTATION' ? 'poste' : 'ligne'
    })`;
  };

  const getListPiquage = (): Piquage[] => {
    return costsDetails.piquage;
  };

  const checkArrow = (key: string) =>
    key.includes('p50') || key.includes('p70') || key.includes('intercalaires');

  // CONTROLE UPDATE COST MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUpdateCostModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateCosts = () => {
    handleUpdate();
  };

  // const navigate = useNavigate();
  // const onEditObstaclesCosts = () => {
  //   // const { quoteId } = useParams<{ quoteId: string }>();
  //   const pathId = detailsStrategy.selectedPath;
  //   navigate(
  //     `/quote/${quoteId}/strategy/${detailsStrategy.id}/traces/${pathId}`,
  //   );
  // };
  console.debug(`obstacles data:`, costsDetails.obstacles);

  return (
    <>
      <Accordion defaultActiveKey="1" alwaysOpen flush>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="border-top mt-2 pt-4">
            {targetType && (
              <BadgeEstimatedCost
                targetType={targetType}
                costsDetails={costsDetails}
              />
            )}
          </Accordion.Header>
          <Accordion.Body>
            <StyledForm>
              <Row>
                <Col>
                  <ListGroup>
                    {quoteContext.totalSeche === 0 && (
                      <ListGroup.Item>
                        <i>
                          {t(
                            'quote.details.strategy_quote.details_costs.infos',
                          )}
                        </i>
                      </ListGroup.Item>
                    )}

                    <ListGroup.Item className="mt-2">
                      <h3>
                        {t('quote.details.strategy_quote.details_costs.trace')}
                      </h3>
                    </ListGroup.Item>
                    {costsDetails.trace !== null && (
                      <>
                        {Object.keys(costsDetails.trace).map(
                          (key, index): JSX.Element => {
                            return (
                              <ListGroup.Item key={index}>
                                <div>
                                  {checkArrow(key) && (
                                    <span className="icon icon-subdirectory_arrow_right me-2"></span>
                                  )}
                                  {t([
                                    `quote.details.strategy_quote.details_costs.labels_cost.${key}`,
                                  ])}
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                  {getCostValueCommas(costsDetails.trace[key])}
                                </div>
                              </ListGroup.Item>
                            );
                          },
                        )}
                      </>
                    )}

                    {/* disabled until I finish the implementation of the obstacle editing page */}
                    <ListGroup.Item className="mt-2">
                      <div>
                        <strong>
                          {t([
                            `quote.details.strategy_quote.details_costs.labels_cost.obstacles`,
                          ])}
                        </strong>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <strong>
                          {getCostValueCommas(costsDetails?.obstacles?.total)}
                        </strong>
                        <Button
                          className="btn-link btn-update"
                          // onClick={onEditObstaclesCosts}
                        >
                          <span className="icon icon-Pensil"></span>
                        </Button>
                      </div>
                    </ListGroup.Item>

                    {/* <ListGroup.Item className="mt-2">
                      <div>
                        {t([
                          `quote.details.strategy_quote.details_costs.labels_cost.obstacles`,
                        ])}
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        {getCostValueCommas(costsDetails?.obstacles?.total)}
                      </div>
                    </ListGroup.Item> */}

                    {targetType !== undefined && (
                      <ListGroup.Item className="mt-2">
                        <h3>
                          {t([
                            `quote.details.strategy_quote.details_costs.type_cost.${targetType}`,
                          ])}
                        </h3>
                      </ListGroup.Item>
                    )}

                    {targetType !== undefined && targetType === 'SUBSTATION' ? (
                      <>
                        {costsDetails.isCostInitialized ? (
                          <>
                            <ListGroup.Item>
                              <div>
                                <strong>{typeWorks()}</strong>
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                <strong>
                                  {targetType === 'SUBSTATION' &&
                                    (quoteContext.totalSeche === 0
                                      ? getCostValueCommas(
                                          getCostsByKey('seche'),
                                        )
                                      : getCostValueCommas(
                                          quoteContext.totalSeche,
                                        ))}
                                </strong>
                                <Button
                                  className="btn-link btn-update"
                                  onClick={openUpdateCostModal}
                                >
                                  <span className="icon icon-Pensil"></span>
                                </Button>
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>
                                <strong>
                                  {t([
                                    `quote.details.strategy_quote.details_costs.labels_cost.contrainte`,
                                  ])}
                                  {` ( ${
                                    poste_facteur_contrainte != null
                                      ? poste_facteur_contrainte
                                      : 0
                                  } % )`}
                                </strong>
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                {getCostValueCommas(
                                  getCostsByKey('contraintes'),
                                )}
                              </div>
                            </ListGroup.Item>

                            <ListGroup.Item>
                              <div>
                                {t([
                                  `quote.details.strategy_quote.details_costs.labels_cost.cmo`,
                                ])}
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                {getCostValueCommas(getCostsByKey('cmo'))}
                              </div>
                            </ListGroup.Item>

                            <ListGroup.Item>
                              <div>
                                {t([
                                  `quote.details.strategy_quote.details_costs.labels_cost.base`,
                                ])}
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                {getCostValueCommas(getCostsByKey('base'))}
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>
                                <span className="icon icon-subdirectory_arrow_right me-2"></span>
                                {t([
                                  `quote.details.strategy_quote.details_costs.labels_cost.p50`,
                                ])}
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                {getCostValueCommas(getCostsByKey('p50'))}
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>
                                <span className="icon icon-subdirectory_arrow_right me-2"></span>
                                {t([
                                  `quote.details.strategy_quote.details_costs.labels_cost.p70`,
                                ])}
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                {getCostValueCommas(getCostsByKey('p70'))}
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>
                                <span className="icon icon-subdirectory_arrow_right me-2"></span>
                                {t([
                                  `quote.details.strategy_quote.details_costs.labels_cost.intercalaires`,
                                ])}
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                {getCostValueCommas(
                                  getCostsByKey('intercalaires'),
                                )}
                              </div>
                            </ListGroup.Item>
                          </>
                        ) : (
                          <>
                            <ListGroup.Item>
                              <div>
                                {t([
                                  `quote.details.strategy_quote.details_costs.labels_cost.estimated_base`,
                                ])}
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                <span>
                                  {getCostValueCommas(getCostsByKey('min'))}
                                </span>
                                <span className="icon-arrow icon-equiv-arrow"></span>
                                <span>
                                  {getCostValueCommas(getCostsByKey('max'))}
                                </span>
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div className="d-flex justify-content-center align-items-center w-100">
                                <Button
                                  size="sm"
                                  className="outline-primary btn-update2"
                                  onClick={openUpdateCostModal}
                                >
                                  <span className="icon icon-Pensil me-1"></span>
                                  {t([
                                    `quote.details.strategy_quote.details_costs.labels_cost.specify_costs`,
                                  ])}
                                </Button>
                              </div>
                            </ListGroup.Item>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {Object.keys(getListPiquage())
                          .filter((item) => item !== 'seche')
                          .map((key, index): JSX.Element => {
                            return (
                              <ListGroup.Item key={index}>
                                <div>
                                  {checkArrow(key) && (
                                    <span className="icon icon-subdirectory_arrow_right me-2"></span>
                                  )}
                                  {t([
                                    `quote.details.strategy_quote.details_costs.labels_cost.${key}`,
                                  ])}
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                  {getCostValueCommas(getCostsByKey(key))}
                                </div>
                              </ListGroup.Item>
                            );
                          })}
                      </>
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </StyledForm>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {costsDetails && isModalOpen && (
        <UpdateCostsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          quoteUid={quoteUid}
          quoteId={quoteId}
          detailsStrategy={detailsStrategy}
          tensionNbr={tensionNbr}
          editable={costsDetails.editable}
          nbrBusbar={nbr_busbar}
          posteType={poste_type}
          contrainte={poste_facteur_contrainte}
          handleUpdate={handleUpdateCosts}
        />
      )}
    </>
  );
};

export default DetailsCosts;
