import React from 'react';
import { type Trace } from 'models/Quote';
import { useTranslation } from 'react-i18next';
import { roundWithPrecision } from '../utils/helpers';
import { VariantBGColor } from 'enums';
// import { VariantClassLabels, VariantBGColor } from 'enums';
import { Alert } from 'react-bootstrap';
import { AirlineStrategyCableColor } from 'utils/constants';

interface BarProps {
  traces: Trace[];
  title?: string;
  zone?: string;
  displayInfos?: boolean;
}

interface StackedBarProps {
  data: any;
  displayInfos?: boolean;
}

const formatNumbersForDisplay = (x: number): string => {
  return roundWithPrecision(2)(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replaceAll(/(?<=[0-9])(\.)(?=[0-9])/g, ',');
};

const StackedBarChart: React.FC<StackedBarProps> = ({
  data,
  displayInfos,
}): JSX.Element => {
  const totalPercentage = data.reduce(
    (sum: number, item: { percentage: number }) => sum + item.percentage,
    0,
  );

  return (
    <>
      <div className="progress-bar">
        {data.map((item: { label: string; percentage: number; color: any }) => (
          <div
            className="progress-value"
            key={item.label}
            style={{
              flex: `${item.percentage / totalPercentage}`,
              backgroundColor: item.color,
            }}
          />
        ))}
      </div>
      <div className={`progress-info`}>
        {data.map((item: { label: string; color: any; distance: number }) => (
          <div className={`infos`} key={item.label}>
            <div
              className={`box`}
              style={{
                height: `${displayInfos === true ? '2rem' : '1rem'}`,
                backgroundColor: item.color,
              }}
            />
            <div className={`box-infos`}>
              <div>
                <b>{`${formatNumbersForDisplay(item.distance)} km`}</b>
              </div>
              {displayInfos === true && (
                <div className={`box-label`}>{item.label}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const ProgressBar: React.FC<BarProps> = ({
  traces,
  title,
  zone,
  displayInfos,
}): JSX.Element => {
  const { t } = useTranslation();

  const fallbackColor = 'hsl(0, 0%, 40%)';
  const getBackgroundColor = (key: string): string => {
    return VariantBGColor[key as keyof typeof VariantBGColor] ?? fallbackColor;
  };

  const getBackgroundColorLa = (key: string): string => {
    // return VariantBGColor[key as keyof typeof VariantBGColor];
    return AirlineStrategyCableColor[key] ?? fallbackColor;
  };

  const progressFiltred = traces.filter(
    (item) => item.ratio !== null && Number(item.ratio) > 0,
  );

  const noSegmentsToDisplay = !(progressFiltred.length > 0);

  if (noSegmentsToDisplay)
    return (
      <Alert variant={'warning'}>
        {t(
          'quote.details.strategy_quote.chart_trace.progress_bar.errors.empty',
        )}
      </Alert>
    );

  return (
    <div className="progress-container">
      <div className="title">{title}</div>
      <StackedBarChart
        displayInfos={Boolean(displayInfos)}
        data={progressFiltred.map((item) => {
          if (zone === 'climate_la')
            return {
              label:
                t(
                  `quote.details.strategy_quote.chart_trace.progress_bar.infos_climate.weather_report`,
                ) +
                ' ' +
                item.name,
              color: getBackgroundColorLa(item.name),
              percentage: item.ratio,
              distance: item.length_km,
            };
          else if (zone === 'climate_ls')
            return {
              label: item.name,
              color: getBackgroundColor(item.name),
              percentage: item.ratio,
              distance: item.length_km,
            };

          const d = {
            label:
              item.name +
              ' ' +
              t(
                `quote.details.strategy_quote.chart_trace.progress_bar.infos_population.${item.name}`,
              ),
            color: getBackgroundColor(item.name),
            percentage: item.ratio,
            distance: item.length_km,
          };

          return d;
        })}
      />
    </div>
  );
};
