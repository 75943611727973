import { simplify } from '@turf/turf';
import { type t1 } from './types';
import { LatLng } from 'leaflet';

export const simplifyHandler = (newPath: t1) => (precision: number) => {
  if (newPath.current == null) return;
  if (!newPath.current.editEnabled()) return;

  const onDegLatApproximation = 111132.954;
  const toleranceInMeters = precision;
  const oldJsonData = newPath.current.toGeoJSON();
  const newJsonData = simplify(oldJsonData, {
    // tolerance: 0.0005, // in crs units.
    tolerance: toleranceInMeters / onDegLatApproximation, // approximation of meters.
    highQuality: true,
  });

  console.log('[Path Simplification] old', oldJsonData, 'new', newJsonData);
  const latLngCoords = (newJsonData.geometry.coordinates as number[][]).map(
    ([long, lat]) => new LatLng(lat, long),
  );

  newPath.current.setLatLngs(latLngCoords);
  newPath.current.toggleEdit();
  newPath.current.toggleEdit();
};
