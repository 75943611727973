/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { getNewPathSaveCheck } from 'services/quotes';
import 'leaflet-editable';
import L, { LatLng } from 'leaflet';
import { center } from '@turf/turf';
import { type t0, type t1 } from './types';
import { IconObstacleUneditedMarker } from '../IconObstacleUneditedMarker';

export const verifyPathHandler =
  (
    quoteUid: string,
    stratUid: string,
    map: t0,
    newPath: t1,
    setNewPathConfirmationStatus: any,
    setPointsSelected: any,
    setObstaclesData: any,
    actionBuffer: any,
    removeObstaclesLayerCb: any,
    obstaclesLayer: any,
    setTmpPathInfo,
    obstacleMarkersGroupLayer: React.MutableRefObject<L.LayerGroup<any>>,
  ) =>
  () => {
    if (newPath.current == null) return;
    const newLineExport = newPath.current.toGeoJSON();
    console.log('Exported New Path:', newLineExport); // TODO: do a backend API call here.
    newPath.current.disableEdit();
    // newPath.current.setStyle(newPathDisabledStyle);

    // newPath.current = null;
    // setPathEditStatus(false);
    setNewPathConfirmationStatus(true);
    setPointsSelected([]);
    // free the actions undo buffer.
    actionBuffer.current = [];

    void getNewPathSaveCheck(quoteUid, stratUid, newLineExport.geometry).then(
      (response) => {
        console.log('obstacles api response', response.data);
        const obstaclesGeojsonResponse = response.data.obstacles;
        setObstaclesData(obstaclesGeojsonResponse);
        setTmpPathInfo(response.data.info);

        if (map == null) return;

        const layerStyle = (feature: any): any => {
          if (feature?.properties?.description?.includes('building')) {
            return {
              color: 'red',
              fillColor: 'red',
              weight: 8,
            };
          }

          return {
            color: 'green',
            fillColor: 'green',
            // weight: 4,
            weight: 8,
          };
        };

        const onEachFeature = (feature: any, layer: any): void => {
          // Create a tooltip but don't open it immediately
          const tooltip = L.tooltip({
            permanent: false,
            direction: 'top',
          });

          // Additional mouseover event to display more properties if needed
          layer.on('click mouseover mousemove', (e) => {
            //   const tooltipContent = `Name: ${lineId}<br> Tension: ${lineTension} <br>Line Type: ${
            //     feature?.properties.configuration as string
            //   } <br><br> ${JSON.stringify(feature?.properties)}`;

            const tooltipContent = Object.entries(feature?.properties ?? {})
              .map(([key, val]) => `${key} : ${val as string}`)
              .join('<br>');

            tooltip.setContent(tooltipContent);

            // Set tooltip position and open it
            tooltip.setLatLng(e.latlng);
            map.openTooltip(tooltip);
          });

          layer.on('mouseout', (e) => {
            map.closeTooltip(tooltip);
          });
        };

        removeObstaclesLayerCb();
        // TODO: make this a state or ref and replace/delete when needed.
        obstaclesLayer.current = L.geoJSON(obstaclesGeojsonResponse, {
          style: layerStyle,
          onEachFeature,
          pointToLayer: (feature, latLng) => {
            const style = layerStyle(feature);

            return new L.CircleMarker(latLng, {
              fillColor: style?.fillColor ?? 'black',
              color: style?.color ?? 'black',
              weight: style?.weight ?? 1,
              radius: style?.weight ?? 1,
            });
          },
        });

        map?.addLayer(obstaclesLayer.current);

        for (const obstacle of obstaclesGeojsonResponse) {
          const obstacleCenter = center(obstacle);

          const point = new LatLng(
            obstacleCenter.geometry.coordinates[1],
            obstacleCenter.geometry.coordinates[0],
          );

          const marker = L.marker(point, {
            icon: IconObstacleUneditedMarker,
            id: obstacle?.properties?.uuid,
          } as any);

          obstacleMarkersGroupLayer.current?.addLayer(marker);
        }
      },
    );
  };
