import { getNewMultiPointPath } from 'services/quotes';
import { type t1 } from './types';
import { LatLng } from 'leaflet';
import { distance } from '@turf/turf';

export const roadRouterHandler =
  (
    newPath: t1,
    pointsSelected: any,
    setPointsSelected: any,
    point: any,
    actionBuffer: any,
  ) =>
  () => {
    if (newPath.current == null) return;

    if (pointsSelected.length > 0) {
      const ll = newPath.current.getLatLngs();
      const concernedPoints = pointsSelected.map((obj) => [
        obj.point.lng,
        obj.point.lat,
      ]);

      console.log('[selected points] to be routed:', concernedPoints);

      void getNewMultiPointPath(concernedPoints).then((res) => {
        if (newPath.current == null) return;
        setPointsSelected([]);

        console.log('[selected points] multi point routing', res.data);
        // const routedPoints = (res.data as any[]).map((pointsList) =>
        //   pointsList.geo.coordinates.map(
        //     ([long, lat]) => new LatLng(lat, long),
        //   ),
        // );

        const routedPoints = (res.data as any[]).reduce(
          (arr, pointsList) => [...arr, ...pointsList.geo.coordinates],
          [],
        );

        const pointSelectStart = pointsSelected[0];
        const pointRouteStart = routedPoints[0];
        const pointRouteEnd = routedPoints.slice(-1)[0];
        const dist1 = distance(
          point([pointSelectStart.point.lng, pointSelectStart.point.lat]),
          point(pointRouteStart),
        );
        const dist2 = distance(
          point([pointSelectStart.point.lng, pointSelectStart.point.lat]),
          point(pointRouteEnd),
        );
        console.log('distance ', dist1, dist2);

        const correctedRoutedPoints =
          dist1 < dist2 ? routedPoints : routedPoints.reverse();

        const newLeafletPoints = [
          ...ll.slice(0, pointSelectStart.index),
          ...correctedRoutedPoints.map((p) => new LatLng(p[1], p[0])),
          ...ll.slice(pointsSelected.slice(-1)[0].index),
        ];

        newPath.current.setLatLngs(newLeafletPoints);
        // newPath.redraw();
        newPath.current.toggleEdit();
        newPath.current.toggleEdit();
      });

      return;
    }

    const newLineExport = newPath.current.toGeoJSON();
    const oldPoints = newLineExport.geometry.coordinates;

    // TODO: add a check that there are at least 3 points in the array
    void getNewMultiPointPath(oldPoints).then((res) => {
      console.log('multi point routing', res.data);
      if (newPath.current == null) return;
      /** This should always return a lineString and Never a MultiLine String
       * TODO: find out why the routing endpoint give out multi-lines sometimes instead of just connecting them into a single line. */

      // const flattenedPointsList = (res.data as any[]).reduce(
      //   (arr, pointsList) => [...arr, ...pointsList.geo.coordinates],
      //   [],
      // );

      // const newLeafletPoints = flattenedPointsList
      const newLeafletPoints = (res.data as any[]).map((pointsList) =>
        pointsList.geo.coordinates.map(([long, lat]) => new LatLng(lat, long)),
      );
      newPath.current.setLatLngs(newLeafletPoints[0]);
      // newPath.redraw();
      newPath.current.toggleEdit();
      newPath.current.toggleEdit();

      actionBuffer.current.push({ action: 'route', data: newLeafletPoints[0] });
    });
  };
