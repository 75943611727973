/* eslint-disable @typescript-eslint/prefer-optional-chain */
import L from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';

import {
  type AlternativePaths,
  type IPointCoords,
  type IStrategyQuote,
} from 'models/Quote';
import { MapScreenshotChild } from './MapScreenshotChild';
import { MapLayersController } from '../../map/MapLayersController';

interface QuoteStrategyProps {
  pointCoords: IPointCoords;
  strategy: IStrategyQuote;
  pathSelected?: AlternativePaths;
}

export const MapScreenshot: React.FC<QuoteStrategyProps> = ({
  pointCoords,
  strategy,
  pathSelected,
}): React.ReactElement => {
  const center = L.latLng(strategy.pointCoords?.lat, strategy.pointCoords?.lng);
  const zoom = 16;

  const mapRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (mapRef.current) {
      // Initialize your Leaflet map here, add markers, layers, etc.
      // mapRef.current.leafletElement.addLayer(...)
    }
  }, []);

  return (
    <>
      {strategy !== undefined && (
        <MapContainer
          preferCanvas={true}
          center={center}
          zoom={zoom}
          zoomControl={false}
          id={`strategy-${strategy.id}`}
          style={{
            width: '600px',
            height: '600px',
          }}
        >
          <MapLayersController />
          <ZoomControl position="bottomleft" />
          <div id="collapse-carte">
            {pathSelected != null && (
              <MapScreenshotChild
                strategy={strategy}
                pointCoords={pointCoords}
                pathSelected={pathSelected}
              />
            )}
          </div>
        </MapContainer>
      )}
    </>
  );
};

export default MapScreenshot;
