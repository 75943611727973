/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// import { t } from 'i18next';
import React from 'react';
import { Col, Badge, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { type Map } from 'leaflet';

import { getCostValue, getDistance } from 'utils';
import ChartTrace from './ChartTrace';
import {
  type CostUserInfo,
  ObstacleSectionComponent,
} from './ObstacleSectionComponent';

const StyledRow = styled(Row)`
  .bar {
    width: 98%;
  }
  .badge {
    &.bg-primary {
      border-radius: 5px;
      background-color: rgba(0, 169, 227, 0.1) !important;
      color: #000;
      text-align: center;
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      width: 82%;
    }

    b {
      font-size: 16px;
    }
  }

  .input-group-text {
    /* background-color: #e9ecef; */
    background: none;
    border: 0px;
  }

  .form-check {
    .form-check-label {
      color: #000;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .label {
        display: flex;
        width: 25px;
        height: 25px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid rgba(0, 169, 227, 1);
        color: #009cdf;
        text-align: center;
        font-family: Inter;
        font-size: 12.047px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 5px;
      }
    }
  }

  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    border: 0px;
  }

  .contact-form {
    border: 0.0625rem solid #cccccc;
    border-radius: 0.1875rem;
    position: relative;
    margin-top: 0.9rem;
    width: 40%;

    .row {
      padding: 1rem;
    }

    .header {
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 999;
      /* top: -0.7rem; */
      top: -1.5rem;
      left: 0.9rem;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      z-index: 1;
      background: white;
    }
  }

  .obstacle-card-item {
    :hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .obstacle-card {
    border-top: 0.12rem solid #cfcfcf;
    padding-top: 0.9rem;
  }

  .obstacle-delete-button {
    width: 32px;
    height: 32px;
    background: none;
    color: black;
    padding: 1px;
    align-self: center;
    margin-top: auto;
    border: none;

    svg {
      text-align: right;
      height: 100%;
      width: 100%;
    }
  }
`;

export const EditedPathInfoCardComponent: React.FC<{
  map: Map | null;
  distance: number;
  isEditingPath: boolean;
  obstaclesData: any[];
  setObstaclesData: any;
  pathBbox: any;
  info: any;
  obstaclesCosts: CostUserInfo[];
  setObstaclesCosts: (state: any) => void;
  obstacleMarkersGroupLayer: React.MutableRefObject<L.LayerGroup<any>>;
  obstaclesLayer: React.MutableRefObject<L.GeoJSON | undefined>;
}> = ({
  map,
  distance,
  isEditingPath,
  obstaclesData,
  setObstaclesData,
  pathBbox,
  info,
  obstaclesCosts,
  setObstaclesCosts,
  obstacleMarkersGroupLayer,
  obstaclesLayer,
}) => {
  const index = -1;

  const targetType = 'SUBSTATION';
  //   const targetType = detailsStrategy?.targetType ?? '';
  // const targetType = '';
  // const tracePath = {
  //   pathInfo: {
  //     climate: [
  //       { length: 0, id: 'string', length_km: 0, name: 'string', ratio: 1 },
  //     ],
  //     population: [
  //       { length: 0, id: 'string', length_km: 0, name: 'string', ratio: 1 },
  //     ],
  //   },
  // };

  const tracePath = info;

  const infoBadges = (
    <>
      <Col>
        <Badge bg="primary" className="mb-2 mx-2">
          {/* <div>{t('quote.details.strategy_quote.update_trace.total')}</div> */}
          {'Distance : '}
          <b>{getDistance(tracePath?.dist)}</b>
        </Badge>
      </Col>
      <Col>
        <Badge bg="primary" className="mx-2">
          {/* <div>
            {t('quote.details.strategy_quote.update_trace.cost_estimated')}
            </div> */}

          {'Coût : '}
          <b>{getCostValue(tracePath?.minCost)}</b>
        </Badge>
      </Col>

      <Col>
        <Badge bg="primary" className="mx-2">
          {/* <div>
            {t('quote.details.strategy_quote.update_trace.cost_estimated')}
            </div> */}

          {'Délais : '}
          <b>{'XX mois'}</b>
        </Badge>
      </Col>
    </>
  );

  return (
    <StyledRow key={index} id={index}>
      <Row>
        {infoBadges}
        <ChartTrace
          key={index}
          alternativePaths={{ pathInfo: tracePath?.pathInfo } as any}
          displayInfos={false}
          targetType={targetType}
        />
      </Row>
      <Row>
        <Col style={{ marginTop: '1em' }}>
          {obstaclesData && obstaclesData.length > 0 ? (
            <Row>
              <h5>{obstaclesData.length} Obstacles</h5>
            </Row>
          ) : undefined}

          {obstaclesData && obstaclesData.length > 0 ? (
            <ObstacleSectionComponent
              key="1"
              map={map}
              pathBbox={pathBbox}
              obstaclesData={obstaclesData}
              setObstaclesData={setObstaclesData}
              obstaclesCosts={obstaclesCosts}
              setObstaclesCosts={setObstaclesCosts}
              obstacleMarkersGroupLayer={obstacleMarkersGroupLayer}
              obstaclesLayer={obstaclesLayer}
            />
          ) : undefined}
        </Col>
      </Row>
    </StyledRow>
  );
};
