import L from 'leaflet';
import React from 'react';
import { ZoomControl, MapContainer } from 'react-leaflet';
import { type IPointCoords, type IStrategyQuote } from 'models/Quote';
import { Loading } from 'shared/Loading';
import { MapQuoteTraceChild } from './MapQuoteTraceChild';
import { MapLayersController } from './MapLayersController';
import 'leaflet-editable';

interface QuoteStrategyProps {
  strategy: IStrategyQuote;
  isLoading: boolean;
  pathSelected: string;
  pointCoords: IPointCoords;
  setMap: any;
  editControls?: any;
}

export const MapQuoteTrace: React.FC<QuoteStrategyProps> = ({
  strategy,
  isLoading,
  pathSelected,
  pointCoords,
  setMap,
  editControls,
}): React.ReactElement => {
  const center = L.latLng(48.8588897, 2.320041);
  const zoom = 17;

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MapContainer
            preferCanvas={true}
            center={center}
            zoom={zoom}
            scrollWheelZoom={true}
            minZoom={7}
            maxZoom={20}
            zoomControl={false}
            dragging={true}
            doubleClickZoom={true}
            editable={true}
            ref={setMap}
          >
            <MapLayersController />
            {editControls}
            <ZoomControl position="bottomleft" />
            {strategy.pointCoords?.lng !== 0 &&
              strategy.pointCoords?.lat !== 0 && (
                <div id="collapse-carte">
                  <MapQuoteTraceChild
                    pointCoords={pointCoords}
                    strategy={strategy}
                    isLoading={isLoading}
                    pathSelected={pathSelected}
                  />
                </div>
              )}
          </MapContainer>
        </>
      )}
    </>
  );
};

export default MapQuoteTrace;
