export const newPathActiveStyle = {
  // color: 'red',
  color: '#00B0FF',
  weight: 12,
  opacity: 1,
  smoothFactor: 1,
};

export const newPathDisabledStyle = {
  color: '#929497',
  weight: 12,
  opacity: 1,
  smoothFactor: 1,
};
