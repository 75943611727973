/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMap } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import * as turf from '@turf/turf';
import { Icon } from 'shared/Icon';

import { DuplicatePathDropdownComponent } from './DuplicatePathDropdownComponent';
import { IconObstacleUneditedMarker } from './IconObstacleUneditedMarker';

import { ReactComponent as RoadRouteIcon } from './road_route.svg';
import { ReactComponent as RoadSnapIcon } from './road_snap.svg';
import { ReactComponent as RoadSimplifyIcon } from './road_simplify.svg';
// import { ReactComponent as MoveHandIcon } from './move_hand.svg';

import { simplifyHandler } from './ActionHandlers/simplify';
import { snapToRoadHandler } from './ActionHandlers/snapToRoad';
import { roadRouterHandler } from './ActionHandlers/roadRoute';
import { deletePointHandler } from './ActionHandlers/deletePoints';
import { redoHandler, undoHandler } from './ActionHandlers/undoRedo';
import { changePathHandler } from './ActionHandlers/changePath';
import { newPathHandler } from './ActionHandlers/newPath';
import { keyboardShortcutsHandler } from './ActionHandlers/keyboardShortcuts';
import { selectHandler } from './ActionHandlers/select';

export const PathEditingButtonsComponent: React.FC<{
  isEditingPath: boolean;
  isConfirmingNewPath: boolean;
  newPath: React.MutableRefObject<L.Polyline | null>;

  actionBuffer;
  point;
  detailsStrategy;
  pointsSelected;
  setPointsSelected;
  setPathEditStatus;

  dragModeEnabled;
  setDragMode;
  setNewPathConfirmationStatus;

  setObstaclesData;
  obstaclesData;
  obstacleMarkersGroupLayer: React.MutableRefObject<L.LayerGroup<any>>;
  setObstaclesCosts;
  obstaclesCosts;

  obstaclesLayer: React.MutableRefObject<L.GeoJSON | undefined>;
  removeObstaclesLayerCb;
  detailsQuote;
  getDefaultEmptyPath;
  setTmpPathInfo;
}> = ({
  isEditingPath,
  isConfirmingNewPath,
  newPath,
  pointsSelected,
  setPointsSelected,
  setPathEditStatus,
  actionBuffer,
  detailsStrategy,
  point,
  dragModeEnabled,
  setDragMode,
  setNewPathConfirmationStatus,
  setObstaclesData,
  obstaclesData,
  obstacleMarkersGroupLayer,
  setObstaclesCosts,
  obstaclesCosts,
  obstaclesLayer,
  removeObstaclesLayerCb,
  detailsQuote,
  getDefaultEmptyPath,
  setTmpPathInfo,
}) => {
  const [isToolboxExpanded, setToolboxStatus] = useState(true);
  const [undoCounter, setUndoCounter] = useState<number>(0);

  // simplify the geometry to make it easier to edit by hand.
  const defaultPrecisionMeters = 10;
  const [desiredPrecision, setDesiredPrecision] = useState(
    defaultPrecisionMeters,
  );

  const map = useMap();

  const newPathAction = newPathHandler(
    map,
    newPath,
    actionBuffer,
    detailsStrategy,
    setPathEditStatus,
    setPointsSelected,
  );
  const selectAction = selectHandler(map, newPath, setPointsSelected);
  const deletePointAction = deletePointHandler(
    map,
    newPath,
    pointsSelected,
    setPointsSelected,
    actionBuffer,
  );
  const roadRouteAction = roadRouterHandler(
    newPath,
    pointsSelected,
    setPointsSelected,
    point,
    actionBuffer,
  );

  const insertCustomObstacleMarker = () => {
    if (!map) return;

    console.log('custom obstacle tool function entered.');
    const newObstacle = map.editTools.startMarker(new LatLng(0, 0), {
      icon: IconObstacleUneditedMarker,
    });
    // console.log('newly created obstacle:.', newObstacle);

    newObstacle.on('editable:drawing:end', (event) => {
      // console.debug('marker inserted', event);

      if (!newPath.current) return;
      const locationClicked = newObstacle.getLatLng();

      const lineCoords = newPath.current?.getLatLngs() ?? [];
      if (!lineCoords) return;
      const lineCoords2 = (lineCoords as LatLng[]).map((p) => [p.lng, p.lat]);

      const turfPath = turf.lineString(lineCoords2);
      const closestPoint = turf.nearestPointOnLine(
        turfPath,
        turf.point([locationClicked.lng, locationClicked.lat]),
      );

      newObstacle.setLatLng(
        new L.LatLng(
          closestPoint.geometry.coordinates[1],
          closestPoint.geometry.coordinates[0],
        ),
      );

      const ratio = closestPoint.properties.location / turf.length(turfPath);

      // TODO: set up these properties properly.
      const generatedCid =
        ((obstaclesData.filter((o) => o?.properties?.userDefined).length ??
          0) as number) + 1;
      const newObstacleData = {
        ...closestPoint,
        properties: {
          userDefined: true,
          cid: generatedCid,
          description: `Obstacle User Defined ${generatedCid}`,
          uuid: 'uuid' + Math.floor(Math.random() * 1e12).toFixed(), // for now until I set it up properly.
          position_start: ratio,
          position_end: ratio,
        },
      };

      (newObstacle.options as any).id = newObstacleData.properties.uuid;
      obstacleMarkersGroupLayer.current.addLayer(newObstacle);

      setObstaclesCosts([
        ...obstaclesCosts,
        {
          uuid: newObstacleData.properties.uuid,
          cost: undefined,
        },
      ]);

      // console.debug(
      //   'closest point: (geojson obj)',
      //   closestPoint,
      //   'newObstacleData',
      //   newObstacleData,
      // );

      setObstaclesData([...obstaclesData, newObstacleData]);
    });
  };

  const selectHandlerMemo = useCallback(selectHandler, [
    map,
    // setPointsSelected,
  ]);

  useEffect(() => {
    if (!map) {
      console.log('no map obj cant attach keyboard bindings');
      return;
    }
    map?.addEventListener(
      'keydown',
      keyboardShortcutsHandler(
        map,
        isEditingPath,
        isConfirmingNewPath,
        dragModeEnabled,
        selectHandlerMemo,
        setDragMode,
        newPath,
        pointsSelected,
        setPointsSelected,
        actionBuffer,
        undoCounter,
        setUndoCounter,
      ),
    );

    return () => {
      map?.removeEventListener(
        'keydown',
        keyboardShortcutsHandler(
          map,
          isEditingPath,
          isConfirmingNewPath,
          dragModeEnabled,
          selectHandlerMemo,
          setDragMode,
          newPath,
          pointsSelected,
          setPointsSelected,
          actionBuffer,
          undoCounter,
          setUndoCounter,
        ),
      );
    };
  }, [map, keyboardShortcutsHandler]);

  return (
    <div
      style={{
        boxSizing: 'border-box',
        textAlign: 'right',
        // display: 'column',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '5px',
        paddingTop: '5px',
        backgroundColor: '#f5f5f5',
        borderRadius: '5px',
      }}
    >
      {!isEditingPath ? (
        <DuplicatePathDropdownComponent
          detailsStrategy={detailsStrategy}
          clickHandler={newPathAction}
        />
      ) : null}

      {!isEditingPath ? (
        <div
          style={{
            border: '2px solid black',
            textAlign: 'center',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
          }}
          onClick={newPathAction(getDefaultEmptyPath())}
        >
          <Icon iconName="FilePlusFill" className="back-icon" />
          New path
        </div>
      ) : null}

      {isEditingPath && !isConfirmingNewPath ? (
        <div
          style={{
            border: '2px solid black',
            // textAlign: 'center',
            textAlign: 'left',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
          }}
          onClick={() => {
            simplifyHandler(newPath)(desiredPrecision);
            setDesiredPrecision(desiredPrecision * 1.5);
          }}
          title={`Simplify path (tolerance: ${Math.round(
            desiredPrecision,
          )} metres)`}
        >
          {/* <Icon className="back-icon" iconName="Slash" /> */}
          <RoadSimplifyIcon
            className="back-icon"
            style={{ color: 'black', width: '32px', height: '32px' }}
          />
          {isToolboxExpanded ? 'Simplify path' : undefined}
        </div>
      ) : null}

      {isEditingPath && !isConfirmingNewPath ? (
        <div
          style={{
            border: '2px solid black',
            // textAlign: 'center',
            textAlign: 'left',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
          }}
          onClick={snapToRoadHandler(newPath)}
          title="Road Snap"
        >
          {/* <Icon className="back-icon" iconName="Magic" /> */}
          <RoadSnapIcon
            className="back-icon"
            style={{ color: 'black', width: '32px', height: '32px' }}
          />
          {isToolboxExpanded ? 'Road Snap' : undefined}
        </div>
      ) : null}

      {isEditingPath && !isConfirmingNewPath ? (
        <div
          style={{
            border: '2px solid black',
            // textAlign: 'center',
            textAlign: 'left',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
          }}
          onClick={roadRouteAction}
          title="Road Route"
        >
          {/* <Icon className="back-icon" iconName="Signpost2Fill" /> */}
          <RoadRouteIcon
            className="back-icon"
            style={{ color: 'black', width: '32px', height: '32px' }}
          />
          {isToolboxExpanded ? 'Road Route' : undefined}
        </div>
      ) : null}

      {isEditingPath || isConfirmingNewPath ? (
        <Button
          style={{
            border: '2px solid black',
            textAlign: 'center',
            // textAlign: 'left',
            // display: 'inline-block',
            display: 'flex',
            alignItems: 'center',

            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
            color: 'black',

            fontFamily: '"Inter", sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 'var(--bs-body-font-size)',
          }}
          onClick={insertCustomObstacleMarker}
        >
          <Icon className="back-icon" iconName="GeoAltFill" />
          {isToolboxExpanded ? (
            <span
              style={
                {
                  // margin: 'auto',
                  // textAlign: 'center',
                  // display: 'inline-table',
                }
              }
            >
              Insert Obstacle
            </span>
          ) : undefined}
        </Button>
      ) : null}

      {isEditingPath && !isConfirmingNewPath ? (
        <div
          style={{
            border: '2px solid black',
            // textAlign: 'center',
            textAlign: 'left',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
          }}
          onClick={selectAction}
          title={isToolboxExpanded ? 'Select' : 'Move'}
        >
          {dragModeEnabled ? (
            <>
              <Icon className="back-icon" iconName="BoundingBox" />
              {isToolboxExpanded ? 'Select' : undefined}
            </>
          ) : (
            <>
              <Icon className="back-icon" iconName="HandIndex" />
              {isToolboxExpanded ? 'Move' : undefined}
            </>
          )}
        </div>
      ) : null}

      {isEditingPath && !isConfirmingNewPath ? (
        <div
          style={{
            border: '2px solid black',
            // textAlign: 'center',
            textAlign: 'left',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
          }}
          onClick={deletePointAction}
          title="Delete Points"
        >
          <Icon className="back-icon" iconName="EraserFill" />
          {isToolboxExpanded ? 'Delete Points' : undefined}
        </div>
      ) : null}

      {isEditingPath && !isConfirmingNewPath ? (
        <Button
          disabled={actionBuffer.current.length === 0}
          style={{
            border: '2px solid black',
            // textAlign: 'center',
            textAlign: 'left',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
            color: 'black',

            fontFamily: '"Inter", sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 'var(--bs-body-font-size)',
          }}
          onClick={undoHandler(
            map,
            newPath,
            actionBuffer,
            undoCounter,
            setUndoCounter,
          )}
        >
          <Icon className="back-icon" iconName="Arrow90degLeft" />
          {isToolboxExpanded ? 'Undo' : undefined}
        </Button>
      ) : null}

      {isEditingPath && !isConfirmingNewPath ? (
        <Button
          disabled={
            !(
              undoCounter < actionBuffer.current.length &&
              actionBuffer.current.length > 0
            )
          }
          style={{
            border: '2px solid black',
            textAlign: 'center',
            // textAlign: 'left',
            // display: 'inline-block',
            display: 'flex',
            alignItems: 'center',

            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
            color: 'black',

            fontFamily: '"Inter", sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 'var(--bs-body-font-size)',
          }}
          onClick={redoHandler(
            map,
            newPath,
            actionBuffer,
            undoCounter,
            setUndoCounter,
          )}
        >
          <Icon className="back-icon" iconName="Arrow90degRight" />
          {isToolboxExpanded ? (
            <span
              style={
                {
                  // margin: 'auto',
                  // textAlign: 'center',
                  // display: 'inline-table',
                }
              }
            >
              Redo
            </span>
          ) : undefined}
        </Button>
      ) : null}

      {isEditingPath && isConfirmingNewPath ? (
        <div
          style={{
            border: '2px solid black',
            // textAlign: 'center',
            textAlign: 'left',
            display: 'inline-block',
            padding: '3px 6px',
            borderRadius: '6px',
            cursor: 'pointer',
            background: 'white',
          }}
          onClick={changePathHandler(newPath, setNewPathConfirmationStatus)}
        >
          <Icon iconName="XCircleFill" className="back-icon" />
          <span style={{ textAlign: 'left' }}> Change Path </span>
        </div>
      ) : null}

      <div
        style={{
          // border: '2px solid black',
          textAlign: 'center',
          // textAlign: 'left',
          display: 'inline-block',
          // padding: '3px 6px',
          // borderRadius: '6px',
          // cursor: 'pointer',
          // background: 'white',
        }}
        onClick={() => {
          setToolboxStatus(!isToolboxExpanded);
        }}
      >
        {isToolboxExpanded ? (
          <>
            <Icon iconName="ChevronDoubleLeft" className="back-icon" />
            <span style={{ textAlign: 'left' }}> Réduire </span>
          </>
        ) : (
          <Icon iconName="ChevronDoubleRight" className="back-icon" />
        )}
      </div>
    </div>
  );
};
