import 'leaflet-editable';
import L from 'leaflet';

import ObstacleToDefineImgMarker from 'images/ObstacleToDefine2.svg';
import ObstacleDefinedImgMarker from 'images/ObstacleDefined.svg';

export const IconObstacleUneditedMarker = new L.Icon({
  iconUrl: ObstacleToDefineImgMarker,
  iconAnchor: [13, 32],
  popupAnchor: [10, -20],
  iconSize: [30, 35],
});

export const IconObstacleEditedMarker = new L.Icon({
  iconUrl: ObstacleDefinedImgMarker,
  iconAnchor: [13, 32],
  popupAnchor: [10, -20],
  iconSize: [30, 35],
});
