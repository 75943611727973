/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import 'leaflet-editable';
import { type LatLng } from 'leaflet';
import { type t1, type t0 } from './types';

export const deleteSelectedPoints =
  (
    map: t0,
    newPath: t1,
    pointsSelected: any,
    setPointsSelected: any,
    actionBuffer: any,
  ) =>
  () => {
    if (newPath.current == null) return;
    if (map == null) {
      console.log('delete keyboard shortcut pressed. map not found');
      return;
    }

    // console.log(
    //   `Delete Selected points Action. SelectedPointState:`,
    //   pointsSeleted,
    // );

    const ll = newPath.current.getLatLngs() as LatLng[];
    const newPoints = ll.filter(
      (p) => !pointsSelected.some(({ point }) => point.equals(p)),
    );
    newPath.current.setLatLngs(newPoints);
    // console.log('newPath old_coord:', ll, 'new_coord:', newPoints);
    // console.log('newPath state var: ', newPath);

    // doing this force the re-drawing and removal of stale vertex markers & middle markers.
    newPath.current.toggleEdit();
    newPath.current.toggleEdit();

    // reset the list of selected points.
    setPointsSelected([]);

    actionBuffer.current.push({ action: 'selection:delete', data: newPoints });
  };

export const deselectPoints = (newPath: t1, setPointsSelected: any) => () => {
  setPointsSelected([]);
  if (!newPath.current) return;
  // newPath.redraw();
  newPath.current.toggleEdit();
  newPath.current.toggleEdit();
};

export const deletePointHandler =
  (
    map: t0,
    newPath: t1,
    pointsSelected: any,
    setPointsSelected: any,
    actionBuffer: any,
  ) =>
  () => {
    deleteSelectedPoints(
      map,
      newPath,
      pointsSelected,
      setPointsSelected,
      actionBuffer,
    )();
  };
