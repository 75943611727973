/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import L from 'leaflet';
import React from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';

import { type IStrategyQuote, type IPointCoords } from 'models/Quote';
import MapQuoteChild from './MapQuoteChild';
import { MapLayersController } from './MapLayersController';

interface QuoteStrategyProps {
  pointCoords: IPointCoords;
  strategies?: IStrategyQuote[];
  isLoading?: boolean;
}

export const MapQuote: React.FC<QuoteStrategyProps> = ({
  strategies,
  pointCoords,
  isLoading,
}): React.ReactElement => {
  const center = L.latLng(pointCoords?.lat, pointCoords?.lng);
  const zoom = 16;

  return (
    <MapContainer
      preferCanvas={true}
      center={center}
      zoom={zoom}
      scrollWheelZoom={true}
      minZoom={7}
      maxZoom={20}
      zoomControl={false}
      dragging={true}
      doubleClickZoom={true}
    >
      <ZoomControl position="bottomleft" />
      <MapLayersController />
      {pointCoords?.lng !== 0 && pointCoords?.lat !== 0 && (
        <div id="collapse-carte">
          <MapQuoteChild pointCoords={pointCoords} strategies={strategies} />
        </div>
      )}
    </MapContainer>
  );
};

export default MapQuote;
