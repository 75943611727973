import axios, { type AxiosError } from 'axios';
import axiosAuthRefresh from 'axios-auth-refresh';
import { getAccessToken, getRefreshToken, setAccessToken } from './user';

const expiryDates = {
  access: new Date(0),
  refresh: new Date(0),
};

// (process.env.REACT_APP_DEV && (process.env.REACT_APP_DEV.length > 0)) ?
const backendUrl = `${
  process.env.REACT_APP_DEV ?? window.location.href + '/api'
}/v1`;
console.log('backend-url', backendUrl);

export const api = axios.create({
  baseURL: backendUrl,
});

// Function to refresh access token using refresh token (replace with your backend logic)
const refreshAccessToken = async (_: AxiosError): Promise<void> => {
  const refreshToken = getRefreshToken() ?? '';

  if (!(refreshToken?.length > 0)) {
    return;
  }

  const response = await api.post('/user/refresh', { refreshToken }, {
    skipAuthRefresh: true,
  } as any);
  const accessToken = response.data.accessToken ?? '';
  if (!(accessToken.length > 0)) {
    return;
  }

  const accessTokenExpiry = new Date(response.data.accessTokenExpiry ?? 0);

  setAccessToken({
    value: accessToken,
    expires: accessTokenExpiry,
  });
};

const refreshStatusCodes = [401, 403];

// Custom logic to determine if refresh is needed (60 seconds before expiry)
const shouldRefresh = (error: AxiosError): boolean => {
  if (refreshStatusCodes.includes(error.status ?? 0)) {
    return true;
  }

  const secondsBeforeExpiry = 60;
  const now = new Date();
  const updateTime = new Date(
    expiryDates.access.getTime() - 1000 * secondsBeforeExpiry,
  );

  return updateTime < now;
};

axiosAuthRefresh(api, refreshAccessToken, {
  // Optional: Set custom status codes to handle during refresh attempts (default: 401)
  statusCodes: refreshStatusCodes,
  shouldRefresh,
});

// Intercept requests to add access token to headers
api.interceptors.request.use(
  (config) => {
    const token = getAccessToken() ?? '';
    if (token.length > 0) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  async (error) => await Promise.reject(error),
);
