import { type t0, type t1 } from './types';

export const cancelPathHandler =
  (
    map: t0,
    newPath: t1,
    actionBuffer: any,
    setPathEditStatus: any,
    setPointsSelected: any,
  ) =>
  () => {
    if (newPath.current == null) return;
    if (map == null) return;

    newPath.current.disableEdit();
    newPath.current.removeFrom(map);

    newPath.current = null;
    setPathEditStatus(false);
    setPointsSelected([]);
    // free the actions undo buffer.
    actionBuffer.current = [];
  };
