/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable eqeqeq */
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Icon } from 'shared/Icon';

import 'leaflet-editable';
// import { newPathHandler } from './ActionHandlers/newPath';
import { type LatLngExpression } from 'leaflet';
import { type IStrategyQuote } from 'models/Quote';

// const clickHandler = newPathHandler(
//     map,
//     newPath,
//     actionBuffer,
//     detailsStrategy,
//     setPathEditStatus,
//     setPointsSelected,
//   );
export const DuplicatePathDropdownComponent: React.FC<{
  detailsStrategy: IStrategyQuote;
  clickHandler: (
    preExistingPath: LatLngExpression[] | LatLngExpression[][],
  ) => () => void;
}> = ({ detailsStrategy, clickHandler }) => (
  <>
    <Dropdown
      style={{
        // textAlign: 'left',
        // display: 'flex',

        border: '2px solid black',
        borderRadius: '6px',
        textAlign: 'center',
        // display: 'inline-block',
        // padding: '3px 6px',
        // cursor: 'pointer',
        // background: 'white',
        // color: 'black',

        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 'var(--bs-body-font-size)',
      }}
    >
      <Dropdown.Toggle
        style={{
          // border: '2px solid black',
          // borderRadius: '6px',
          // textAlign: 'center',
          // display: 'inline-block',
          padding: '3px 6px',
          cursor: 'pointer',
          background: 'white',
          color: 'black',

          fontFamily: '"Inter", sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 'var(--bs-body-font-size)',
        }}
      >
        <Icon iconName="Union" color="black" className="back-icon" />
        Duplicate & modify Path
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {detailsStrategy?.alternativePaths.map((path, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => {
              // console.log(`duplicate path nb ${index}:`, path.uid);
              clickHandler(path.polylineCoords)();
            }}
          >
            {`Trace ${index}`}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </>
);
