/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import 'leaflet-editable';
import { Polyline, type LatLngExpression } from 'leaflet';
import { newPathActiveStyle } from './pathStyle';
import { type t0, type t1 } from './types';

export const newPathHandler =
  (
    map: t0,
    newPath: t1,
    actionBuffer: any,
    detailsStrategy: any,
    setPathEditStatus: any,
    setPointsSelected: any,
  ) =>
  (preExistingPath: LatLngExpression[] | LatLngExpression[][]) =>
  () => {
    if (map == null) return;
    if (!detailsStrategy) return;

    // const newLine = map.editTools.startPolyline();
    const newLine = new Polyline(preExistingPath, newPathActiveStyle);

    // newLine.addLatLng(start);
    // newLine.addLatLng(end);
    newLine.addTo(map);
    newLine.enableEdit();

    newLine.on('editable:vertex:deleted', (e) => {
      // console.log('[DEBUG] editable:vertex:deleted', e);
      actionBuffer.current.push({
        action: 'editable:vertex:deleted',
        data: newLine.getLatLngs(),
      });
    });

    newLine.on('editable:vertex:dragend', (e) => {
      // console.log('[DEBUG] editable:vertex:dragend', e);
      actionBuffer.current.push({
        action: 'editable:vertex:dragend',
        data: newLine.getLatLngs(),
      });
    });

    newLine.on('editable:vertex:new', (e) => {
      // console.log('[DEBUG] editable:vertex:new', e);
      actionBuffer.current.push({
        action: 'editable:vertex:new',
        data: newLine.getLatLngs(),
      });
    });

    newPath.current = newLine;
    setPathEditStatus(true);
    setPointsSelected([]);
    actionBuffer.current.push({ action: 'new', data: preExistingPath });
  };
