/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Alert, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { type IPoint } from 'models/Strategy';
import StrategyQuote from './strategy/StrategyQuote';
import {
  type IStrategyQuote,
  type IQuote,
  type IPointCoords,
} from 'models/Quote';
import MapQuote from './map/MapQuote';
import { compareItems, formattedDate } from 'utils';
import InfosContact from './strategy/InfosContact';
import { ClientTypeLabels, EneryTypeLabels } from 'enums';
import { getDetailsQuote } from 'services/quotes';
import { Loading } from 'shared/Loading';
import DeleteQuoteModal from './strategy/DeleteQuoteModal';
import { useDeviceType } from 'shared/useDeviceType';

import QuoteGenerateWord from './strategy/generateWord/QuoteGenerateWord';
import MapScreenshot from './strategy/generateWord/MapScreenshot';
import { userIsLogged } from 'services/user';
import { showErrorNotification } from 'services/NotificationService';

const StyledContainer = styled(Container)`
  .quote {
    padding-top: 0;
    height: calc(100vh - 7.4rem);

    .btn-display-strategy > .search-icon {
      font-size: O.8rem !important;
    }

    .table {
      margin-bottom: 0;
      border: none;
      border-color: transparent;
      padding-bottom: 0;
      border-spacing: 0;
      margin-top: 0;
      line-height: 0.5;
      text-align: left;
      padding-left: 0;
      color: #000000;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: break-spaces;

      td {
        padding: 0.2rem 0.3rem;

        &.border-r {
          border-right: 1px solid rgba(133, 133, 133, 1);
        }
      }
    }
  }
  .description-col-rak {
    cursor: pointer;
    border-bottom: 3px solid var(--rak-rgba-blackMana);
    padding: 0.8rem 0;

    // &:hover,
    // &.active {
    //   background-color: var(--rak-wintersDay);
    //   opacity: 1;
    // }
  }

  .infos {
    position: relative;

    .icon {
      position: absolute;
      top: 0;
      right: 10%;
      font-size: 1.8rem;
      color: rgba(28, 27, 31, 1);
    }
  }
  .quote .back-icon {
    font-size: 2rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
  }

  .btn-rak-primary,
  .btn-danger {
    font-size: 1rem;
    height: 2.5rem;
    border-radius: 4px;
  }
  .details_strategies {
    overflow-y: auto;
    height: calc(100vh - 21rem);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .isDisplay {
    display: block;
  }
  .notDisplay {
    display: none;
  }
  .description-col-rak {
    position: relative;
    padding-left: 5px;

    &:hover,
    &.active {
      background-color: var(--rak-wintersDay);
      opacity: 1;
    }

    > .mapStrategy {
      position: relative;
      z-index: 11;
      .leaflet-container {
        position: absolute;
        top: -9999px;
        left: -9999px;
        // position: absolute;
        // clip: rect(1px 1px 1px 1px); /* syntaxe pour IE6 & IE7 */
        // clip: rect(1px, 1px, 1px, 1px);
        // position: absolute !important;
        // z-index: 0 !important;
        // overflow: hidden;
      }
    }

    .btn.active,
    .btn:hover,
    .btn.show,
    .btn:active {
      color: #000000;
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
`;

const QuoteDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const locationData = useLocation();
  const { quoteId } = useParams();
  const quoteUid = locationData?.state?.quoteUid ?? quoteId;
  if (!quoteUid) navigate('/quote');

  if (!userIsLogged()) navigate('/quote');

  const { isMobile, isTablet } = useDeviceType();
  const isDesktop = !isMobile && !isTablet;

  const [detailsQuote, setDetailsQuote] = useState<IQuote>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openQuote, setOpenQuote] = useState<boolean>(false);
  // const [strategyMap, setStrategyMap] = useState<StrategyMap[]>([]);

  useEffect(() => {
    setLoading(true);
    if (quoteUid) {
      void getDetailsQuote(quoteUid).then((result) => {
        if (result.status === 404) {
          showErrorNotification(t('quote.details.errors.404'));
          navigate('/quote');
        }

        const quoteDetails = result.data;
        setDetailsQuote(quoteDetails);
        setLoading(false);

        // captureImage();

        // const mapList: StrategyMap[] = [];

        // if (result.strategies.length > 0) {
        //   setTimeout(() => {
        //     // Create a blob from the document
        //     result.strategies.map((strategy: IStrategyQuote, index: number) => {
        //       const container: any = document.getElementById(
        //         `strategy-${strategy.id}`,
        //       );
        //       if (container) {
        //         void captureMapAsImage(container).then((dataUrl) => {
        //           if (dataUrl !== undefined) {
        //             // const baseImg = dataUrl.split(',')[1];
        //             console.log('strategyMap:', dataUrl);
        //             // mapList.push({
        //             //   id: strategy.id,
        //             //   img: baseImg,
        //             // });
        //           }
        //         });
        //       }
        //     });

        //     // setStrategyMap(mapList);
        //   }, 5000);
        // }
      });
    }
  }, [quoteUid]);

  const pointCoords: IPointCoords = {
    lat: Number(detailsQuote?.lat ?? 0),
    lng: Number(detailsQuote?.long ?? 0),
    label: detailsQuote?.address,
  };

  // CONTROLE MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = (quoteId: string, quoteUid: string, idStrat: string) => {
    navigate(`/quote/${quoteId}/strategy/${idStrat}`, { state: { quoteUid } });
  };

  return (
    <>
      {!isLoading && detailsQuote ? (
        <>
          {!isDesktop && !openQuote && (
            <Button
              onClick={() => {
                setOpenQuote(!openQuote);
              }}
              aria-controls="example-collapse-text"
              aria-expanded={openQuote}
              variant="secondary"
              size="sm"
              className={`btn-display-strategy d-lg-none`}
            >
              <i className="icon icon-list_bulleted search-icon mx-1"></i>
              {t('strategy.content.display_list')}
            </Button>
          )}

          <StyledContainer fluid>
            <Row className={'quote'}>
              <Col
                sm="12"
                md="6"
                lg="6"
                xxl="5"
                className={`quote_details ${
                  isDesktop || (!isDesktop && openQuote)
                    ? 'isDisplay'
                    : 'notDisplay'
                } ${!isDesktop ? 'mt-3' : ''}`}
              >
                <Row>
                  <Col
                    xs="12"
                    md="12"
                    lg="1"
                    className={`d-flex ${
                      isDesktop
                        ? 'justify-content-center'
                        : 'justify-content-between align-items-center p-2  mt-3 mb-3'
                    }`}
                  >
                    <Icon
                      iconName="ArrowLeftSquare"
                      color="rgba(0, 156, 223, 1)"
                      onClick={() => {
                        navigate(`/quote`);
                      }}
                      className={`back-icon ${isDesktop ? 'mt-4' : ''}`}
                    />
                    {!isDesktop && openQuote && (
                      <Button
                        onClick={() => {
                          setOpenQuote(!openQuote);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={openQuote}
                        variant="secondary"
                        size="sm"
                        className={`btn-display-map d-lg-none`}
                      >
                        <i className="icon icon-map search-icon mx-1"></i>
                        {t('strategy.content.display_map')}
                      </Button>
                    )}
                    {!isDesktop && openQuote && (
                      <div className="d-flex justify-content-center align-items-center">
                        <QuoteGenerateWord detailsQuote={detailsQuote} />
                        <Button variant="danger" onClick={openModal} size="sm">
                          <Icon
                            iconName="Trash3"
                            color="white"
                            className="icon"
                          />
                        </Button>
                      </div>
                    )}
                  </Col>
                  <Col xs="12" md="12" lg="11" className="details">
                    <div className="details_strategy">
                      <div className="details_header">
                        {detailsQuote?.id !== undefined && (
                          <h3>
                            {t('quote.details.title')}&nbsp;
                            <strong>{detailsQuote?.id}</strong>
                          </h3>
                        )}

                        {isDesktop && (
                          <div className="d-flex justify-content-center">
                            <QuoteGenerateWord detailsQuote={detailsQuote} />
                            <Button
                              variant="danger"
                              onClick={openModal}
                              size="sm"
                            >
                              <Icon
                                iconName="Trash3"
                                color="white"
                                className="icon"
                              />
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="details_content mt-3">
                        <div className="d-none d-md-block d-lg-block">
                          <Row>
                            <Col xs="12" md="8" className="border-right">
                              {detailsQuote?.address ? (
                                <>
                                  <p>{t('quote.details.address')}</p>
                                  <p
                                    title={detailsQuote?.address}
                                    className="text-truncate"
                                  >
                                    <strong className="text-truncate">
                                      {detailsQuote?.address}
                                    </strong>{' '}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p>{t('quote.details.address')}</p>
                                  <p>
                                    <strong>{t('quote.details.lat')}</strong>
                                    &nbsp;
                                    {detailsQuote?.lat}&nbsp;&nbsp;
                                    <strong>{t('quote.details.long')}</strong>
                                    &nbsp;
                                    {detailsQuote?.long}
                                  </p>
                                </>
                              )}

                              <p className="text-truncate">
                                {t('quote.details.type')}
                              </p>
                              <p>
                                <strong>
                                  {
                                    ClientTypeLabels[
                                      detailsQuote?.clientType as keyof typeof ClientTypeLabels
                                    ]
                                  }
                                </strong>
                              </p>
                              {detailsQuote?.energyType && (
                                <>
                                  <p className="text-truncate">
                                    {t('quote.details.energy')}
                                  </p>
                                  <p>
                                    <strong>
                                      {
                                        EneryTypeLabels[
                                          detailsQuote?.energyType as keyof typeof EneryTypeLabels
                                        ]
                                      }
                                    </strong>
                                  </p>
                                </>
                              )}
                            </Col>
                            <Col xs="12" md="4" className="infos">
                              {detailsQuote?.dateMiseEnService != null && (
                                <>
                                  <p>{t('quote.details.date')}</p>
                                  <p>
                                    <strong>
                                      {detailsQuote?.dateMiseEnService !=
                                        null &&
                                        formattedDate(
                                          detailsQuote?.dateMiseEnService,
                                        )}
                                    </strong>{' '}
                                  </p>
                                </>
                              )}
                              {detailsQuote?.power != null && (
                                <>
                                  <p>{t('quote.details.power')}</p>
                                  <p>
                                    <strong>{`${detailsQuote?.power} MW`}</strong>
                                  </p>
                                </>
                              )}

                              {detailsQuote != null && (
                                <InfosContact detailsQuote={detailsQuote} />
                              )}
                            </Col>
                          </Row>
                        </div>

                        <div className="d-block d-sm-none">
                          <Row>
                            <Col xs="12" md="8" className="mb-3">
                              {detailsQuote?.address ? (
                                <p title={detailsQuote?.address}>
                                  {t('quote.details.address')}:&nbsp; &nbsp;
                                  {detailsQuote?.address}
                                </p>
                              ) : (
                                <>
                                  {detailsQuote?.long && detailsQuote?.lat && (
                                    <p>
                                      {t('quote.details.address')}: &nbsp;
                                      &nbsp;
                                      <strong>{t('quote.details.lat')}</strong>
                                      &nbsp;
                                      {detailsQuote?.lat}&nbsp;&nbsp;
                                      <strong>{t('quote.details.long')}</strong>
                                      &nbsp;
                                      {detailsQuote?.long}
                                    </p>
                                  )}
                                </>
                              )}
                            </Col>
                            <Col xs="12" md="8" className="infos">
                              <Table responsive="sm" bordered={false}>
                                <tbody>
                                  <tr>
                                    <td width={'43%'} className="border-r">
                                      {t('quote.details.type')}:&nbsp;
                                    </td>
                                    <td style={{ paddingLeft: '0.8rem' }}>
                                      {
                                        ClientTypeLabels[
                                          detailsQuote?.clientType as keyof typeof ClientTypeLabels
                                        ]
                                      }
                                    </td>
                                  </tr>
                                  {detailsQuote?.energyType && (
                                    <tr>
                                      <td width={'43%'} className="border-r">
                                        {t('quote.details.energy')}:&nbsp;
                                      </td>
                                      <td style={{ paddingLeft: '0.8rem' }}>
                                        {
                                          EneryTypeLabels[
                                            detailsQuote?.energyType as keyof typeof EneryTypeLabels
                                          ]
                                        }
                                      </td>
                                    </tr>
                                  )}
                                  {detailsQuote?.dateMiseEnService != null && (
                                    <tr>
                                      <td width={'43%'} className="border-r">
                                        {t('quote.details.date')}:&nbsp;
                                      </td>
                                      <td style={{ paddingLeft: '0.8rem' }}>
                                        {detailsQuote?.dateMiseEnService !=
                                          null &&
                                          formattedDate(
                                            detailsQuote?.dateMiseEnService,
                                          )}
                                      </td>
                                    </tr>
                                  )}
                                  {detailsQuote?.power != null && (
                                    <tr>
                                      <td width={'43%'} className="border-r">
                                        {t('quote.details.power')}:&nbsp;
                                      </td>
                                      <td
                                        style={{ paddingLeft: '0.8rem' }}
                                      >{`${detailsQuote?.power} MW`}</td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                              {detailsQuote != null && (
                                <InfosContact detailsQuote={detailsQuote} />
                              )}
                            </Col>
                          </Row>
                        </div>

                        <Row className="mt-2 details_strategies">
                          {detailsQuote?.strategies !== undefined &&
                          detailsQuote?.strategies.length > 0 ? (
                            <>
                              {detailsQuote?.strategies
                                .sort(compareItems)
                                .map(
                                  (strategy: IStrategyQuote, index: number) => {
                                    const alternative =
                                      strategy.alternativePaths.find(
                                        (item: any) =>
                                          // eslint-disable-next-line eqeqeq
                                          item.uid == strategy.selectedPath,
                                      );
                                    const point: IPoint = {
                                      code: strategy.point.code,
                                      name: strategy.point.name,
                                      details: strategy.point.details,
                                      type: 'ok',
                                      pointType: 'ok',
                                      substation: 1,
                                    };

                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    const pointCoords: IPointCoords = {
                                      lat: Number(detailsQuote?.lat),
                                      lng: Number(detailsQuote?.long),
                                      label: detailsQuote?.address,
                                    };

                                    return (
                                      <Col
                                        key={index}
                                        className="description-col-rak"
                                        md="12"
                                        onClick={() =>
                                          handleClick(
                                            detailsQuote.id,
                                            detailsQuote.uid,
                                            strategy.id,
                                          )
                                        }
                                      >
                                        <StrategyQuote
                                          point={point}
                                          targetType={strategy?.targetType}
                                          lineType={strategy?.lineType}
                                          distance={alternative?.distance}
                                          cost={alternative?.cost}
                                          id={strategy.id}
                                          isReady={strategy.isReady}
                                          tension={strategy.tension_nbr}
                                          discarded={strategy.discarded}
                                          costsDetails={strategy.costsDetails}
                                          temperatureAlert={
                                            strategy.temperature_alert
                                          }
                                        />
                                        <div className="mapStrategy">
                                          <MapScreenshot
                                            pointCoords={pointCoords}
                                            strategy={strategy}
                                            pathSelected={alternative}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  },
                                )}
                            </>
                          ) : (
                            <Alert variant={'warning'}>
                              {t('quote.details.errors.empty')}
                            </Alert>
                          )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="12" lg="6" xxl="7" className={`map`}>
                {detailsQuote && (
                  <MapQuote
                    pointCoords={pointCoords}
                    strategies={detailsQuote?.strategies}
                    isLoading={isLoading}
                  />
                )}
              </Col>
            </Row>
          </StyledContainer>
          {detailsQuote?.uid && (
            <DeleteQuoteModal
              isOpen={isModalOpen}
              onClose={closeModal}
              quoteUid={detailsQuote?.uid}
              quoteId={detailsQuote?.id}
            />
          )}

          {/* <StyledModalPDF
            className="custom"
            show={isGeneratedPDF}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={false}
            fullscreen
          >
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body>
              {detailsQuote && (
                <QuoteDetailsPDF
                  detailsQuote={detailsQuote}
                  mapImage={mapImage}
                />
              )}
            </Modal.Body>
          </StyledModalPDF> */}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default QuoteDetails;
