/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// environnement
import {
  type OpUserDefined,
  type Climate,
  type Population,
  type Poste,
  type Piquage,
  type IStrategyQuote,
} from 'models/Quote';
import { roundWithPrecision } from './helpers';

// ENUM PathInfo: strategy details Quote
type EnumTypePath = Record<string, Population[] | Climate[]>;
type EnumAsArrayTypePath = Array<{
  key: string;
  value: Population[] | Climate[];
}>;

export const EnumPathTrace = (
  population: EnumTypePath,
): EnumAsArrayTypePath => {
  return Object.keys(population)
    .filter((key) => Number.isNaN(+key))
    .map((key: string) => ({
      key,
      value: population[key],
    }));
};

// ENUM costsDetails: strategy details Quote
type EnumTypeCostsDetails = Record<string, Poste[] | Piquage[]>;
type EnumAsArrayTypeostsDetails = Array<{
  key: string;
  value: Poste[] | Piquage[];
}>;

export const EnumCostsDetails = (
  cost: EnumTypeCostsDetails,
): EnumAsArrayTypeostsDetails => {
  return Object.keys(cost)
    .filter((key) => Number.isNaN(+key))
    .map((key: string) => ({
      key,
      value: cost[key],
    }));
};

export const formattedDate = (date: Date): string => {
  return new Date(date).toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formattedDateEn = (date: Date): string => {
  return new Date(date)
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
    .reverse()
    .join('-');
};

// ENUM COST DETAILS: strategy details Quote
export type EnumTypeCost = Record<string, OpUserDefined>;
export type EnumAsArrayTypeCost = Array<{
  key: string;
  value: OpUserDefined;
}>;
export const EnumPathCost = (cost: EnumTypeCost): EnumAsArrayTypeCost => {
  return Object.keys(cost)
    .filter((key) => Number.isNaN(+key))
    .map((key: string) => ({
      key,
      value: cost[key],
    }));
};

export const getCostValue = (value: number): string => {
  return `${Math.round(value).toLocaleString('fr-FR')} k€`;
};

export const getCostValueCommas = (value: number): string => {
  return value !== undefined
    ? `${value.toLocaleString('fr-FR', {
        maximumFractionDigits: 1,
      })} k€`
    : `0 k€`;
};

export const getDistance = (distance: number): string => {
  return distance < 1000
    ? `${Math.round(distance).toString().replace('.', ',')} m`
    : `${roundWithPrecision(2)(Math.round(distance) / 1000)
        .toString()
        .replace('.', ',')} km`;
};

export const formattedPower = (tension: number): string => {
  return `${Math.round(tension / 1000).toString()}KV`;
};

// Custom comparison function
export const compareItems = (a: IStrategyQuote, b: IStrategyQuote) => {
  if (a.discarded === false && b.discarded === false) {
    return 0; // Leave the order unchanged
  } else if (a.discarded === false) {
    return -1; // a should come before b
  } else {
    return 1; // a should come after b
  }
};
