/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { saveQuoteStrategyPath } from 'services/quotes';
import { newPathDisabledStyle } from './pathStyle';
import { type t1 } from './types';
import { type CostUserInfo } from '../ObstacleSectionComponent';

export const savePathHandler =
  (
    newPath: t1,
    detailsStrategy: any,
    detailsQuote: any,
    setPathEditStatus: any,
    setPointsSelected: any,
    actionBuffer: any,
    obstaclesCosts: CostUserInfo[],
    obstaclesData,
  ) =>
  () => {
    if (!detailsStrategy) return;
    if (!detailsQuote) return;
    // if (!trace) return;

    if (newPath.current == null) return;
    const newLineExport = newPath.current.toGeoJSON();
    console.log('Exported New Path:', newLineExport); // TODO: do a backend API call here.
    newPath.current.disableEdit();
    newPath.current.setStyle(newPathDisabledStyle);

    const devisUid = detailsQuote.uid;
    const strategyUid = detailsStrategy.uid;
    const clonedPathUid = '';
    const newPathGeom = newLineExport.geometry;

    void saveQuoteStrategyPath(
      devisUid,
      strategyUid,
      clonedPathUid,
      newPathGeom,
      obstaclesCosts ?? [],
      obstaclesData,
    ).then((response) => {
      console.log('path saved', response.data);
      if (response.data.success === true) {
        newPath.current = null;
        setPathEditStatus(false);
        setPointsSelected([]);
        // free the actions undo buffer.
        actionBuffer.current = [];

        window.location.reload();
        // TODO: dipslay the reason of failure when saving fails.
      }
    });
  };
