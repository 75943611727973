/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Badge,
  Alert,
  Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Icon } from 'shared/Icon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InfosTrace from './InfosTrace';
import { TraceTypeLabels } from 'enums';
import QuoteContext from 'context/QuoteContext';
import { useTranslation } from 'react-i18next';

import {
  type IQuotePost,
  type AlternativePaths,
  type IQuote,
  type IStrategyQuote,
  type IPointCoords,
} from 'models/Quote';
import { type QuoteContextType } from 'types';
import MapQuoteTrace from '../map/MapQuoteTrace';
import ChartTrace from './ChartTrace';
import {
  deleteQuoteStrategyPath,
  getQuoteList,
  setQuoteStrategy,
} from 'services/quotes';
import { Loading } from 'shared/Loading';
import { getCostValue, getDistance } from 'utils';

import 'leaflet-editable';
import L, { type LatLng, type Polyline, type Map } from 'leaflet';
import { length, point } from '@turf/turf';
import { EditedPathInfoCardComponent } from './EditedPathInfoCardComponent';

import { newPathHandler } from './ActionHandlers/newPath';
import { PathEditingButtonsComponent } from './PathEditingButtonsComponent';

import { ReactComponent as VerifyIcon } from './refresh_path.svg';
import classNames from 'classnames';
import { verifyPathHandler } from './ActionHandlers/verifyPath';
import { savePathHandler } from './ActionHandlers/savePath';
import { cancelPathHandler } from './ActionHandlers/cancelPath';

import { ReactComponent as NewPathIcon } from './alt_road.svg';
import { ReactComponent as DeletePathIcon } from './path_delete.svg';
import { ReactComponent as EditPathIcon } from './path_edit_pen.svg';
import { type CostUserInfo } from './ObstacleSectionComponent';

// layers with custom function to retrieve & control, add, and remove obstacle markers.
// L.LayerGroup.include({
//   customGetLayer: function (id) {
//     for (const i in this._layers) {
//       if (this._layers[i].options.id == id) {
//         return this._layers[i];
//       }
//     }
//   },
// });

export const customGetLayer = (layer: L.LayerGroup | any, id: string) => {
  for (const i in layer._layers) {
    if (layer._layers[i].options.id == id) {
      return layer._layers[i];
    }
  }
};

const StyledContainer = styled(Container)`
  .btn-new-path {
    border: 1px solid #009cdf;
    border-radius: 4px;
    color: #009cdf;
    background: #ffffff;

    align-items: center;
    text-align: center;
    display: flex;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;

    :hover {
      background-color: #009cdf;
      color: #ffffff;
    }
  }

  .btn-delete-path {
    background: none;
    width: 100%;
    color: black;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;

    color: #ff0000;
    border: 1px solid #ff0000;

    :hover {
      background-color: #ff0000;
      color: #ffffff;
    }
  }

  .btn-edit-path {
    background: none;
    width: 100%;
    color: black;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;

    border: 1px solid #009cdf;
    color: #009cdf;

    :hover {
      background-color: #009cdf;
      color: #ffffff;
    }
  }

  .btn-link {
    --bs-btn-padding-y: 2px;
    --bs-btn-padding-x: 2px;
    &:hover,
    &.active {
      background-color: transparent;
      border-color: transparent;
      color: rgba(0, 169, 227, 1);
    }

    &.border {
      border-radius: 2px;
      border: 1px solid rgba(0, 127, 182, 1) !important;
    }
  }

  .quote {
    .quote-details {
      padding: 0.3rem;
      height: 94vh;
      overflow-y: auto;
    }
    .trace-details {
      h3 {
        color: #000;
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px; /* 140% */
        text-transform: uppercase;
      }
    }
    .progress-trace {
      width: 98%;
    }
  }

  .sidebar-editing {
    padding: 1rem;

    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .header {
      margin-top: 0.5rem;
      flex-basis: 50px;
    }

    .main {
      margin-top: 1rem;
      overflow-y: scroll;

      max-height: 80vh;
      flex: 1;
    }

    .footer {
      margin-top: 1rem;
      margin-top: auto;
      padding-bottom: 0px;
      margin-bottom: 0px;

      flex-basis: 50px;
      justify-content: space-between;
      border-top: 0.25rem solid #009cdf;
      padding-top: 1rem;

      Button {
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px; /* 140% */
        text-transform: uppercase;

        width: 50%;
        border-width: 0px;
        border-radius: 5px;

        vertical-align: center;

        * {
          margin-right: 0.5rem;
          vertical-align: center;
          height: 100%;
          /* width: 100%; */
        }
      }

      .save-button {
        background: #009cdf;
        margin-left: auto;
      }
      .save-button.disabled {
        background: #d9d9d9;
        color: #919191;
      }

      .cancel-button {
        border-color: #ff0000;
        color: #ff0000;
        background: none;
      }
    }
  }

  .container {
    position: relative;

    .background {
      /* position: absolute; */
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .background.visible {
      filter: blur(6px);
      pointer-events: none;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 35%;

      /* position: relative; */
      .centered-button {
        /* width: 10rem; */

        border-color: #009cdf;
        border-radius: 4px;
        border-width: 1px;

        padding: 10px 20px;
        cursor: pointer;

        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-transform: uppercase;
        color: #009cdf;

        background-color: #ffffff;
      }

      z-index: 1;
      display: none;
    }

    .overlay.visible {
      display: block;
    }
  }
`;

const StyledRow = styled(Row)`
  border-radius: 7px;
  border: 1px solid #bfbfbf;
  margin-bottom: 0.8rem;
  cursor: pointer;

  &.active,
  &:hover {
    border: 2px solid #009cdf;
  }

  .bar {
    width: 98%;
  }
  .badge {
    &.bg-primary {
      border-radius: 5px;
      background-color: rgba(0, 169, 227, 0.1) !important;
      color: #000;
      text-align: center;
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      width: 82%;
    }
  }

  .form-check {
    .form-check-label {
      color: #000;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .label {
        display: flex;
        width: 25px;
        height: 25px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid rgba(0, 169, 227, 1);
        color: #009cdf;
        text-align: center;
        font-family: Inter;
        font-size: 12.047px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 5px;
      }
    }
  }
`;

const UpdateTrace: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { quoteId, idStrategy, idPath } = useParams<{
    quoteId: string;
    idStrategy: string;
    idPath: string;
  }>();

  const locationData = useLocation();
  const quoteUid = locationData?.state?.quoteUid ?? quoteId;
  if (!quoteUid) navigate('/quote');

  const { quoteContext, setQuoteContext } =
    useContext<QuoteContextType>(QuoteContext);

  const {
    quoteContext: { data },
  } = useContext<QuoteContextType>(QuoteContext);

  const [tableItem, setItems] = useState<IQuote[]>([]);
  const [detailsQuote, setDetailsQuote] = useState<IQuote>();
  const [detailsStrategy, setDetailsStrategy] = useState<IStrategyQuote>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dragModeEnabled, setDragMode] = useState<boolean>(true);

  const [quotePost, setQuotePost] = useState<IQuotePost>({
    selectedPath: '',
  });

  const [tmpPathInfo, setTmpPathInfo] = useState<any>(null);

  const getPathSelected = (detailsStrategy: IStrategyQuote) => {
    // SELECTED ALTERNATIVE PATH
    const path = detailsStrategy?.alternativePaths.find(
      (item: AlternativePaths) => item.uid == detailsStrategy?.selectedPath,
    );
    setQuotePost({ ...quotePost, selectedPath: path?.uid });
  };

  const getStrategy = (quote: IQuote, idStrategy: string): void => {
    const strategy = quote.strategies.filter(
      (item: IStrategyQuote) => item.id == idStrategy,
    )[0];
    setDetailsStrategy(strategy);
    getPathSelected(strategy);
  };

  const getQuote = (data: IQuote[], quoteId: string, idStrategy: string) => {
    const quote = data.find((item: IQuote) => item.id === quoteId);
    setDetailsQuote(quote);

    quote && getStrategy(quote, idStrategy);
  };

  const getStrategyQuote = (quoteId: string, idStrategy: string) => {
    setLoading(true);

    if (data.length > 0) {
      setItems(data);
      getQuote(data, quoteId, idStrategy);
      setLoading(false);
    } else {
      void getQuoteList().then(({ data, status }) => {
        setQuoteContext({
          ...quoteContext,
          data,
          isLoading: false,
        });
        setItems(data);
        getQuote(data, quoteId, idStrategy);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    quoteId && idStrategy && getStrategyQuote(quoteId, idStrategy);
  }, []);

  // TEMP: FIX ME this should be refactored into a separate component for displaying the current edited path instead of mixing the menu for displaying the list of path and the current selected one..
  useEffect(() => {
    // quoteId && idStrategy && getStrategyQuote(quoteId, idStrategy);

    console.debug(
      'url param idPath:',
      idPath,
      'detailsStrategy exists',
      !!detailsStrategy,
    );
    if (idPath) {
      const traces = detailsStrategy?.alternativePaths.filter(
        (x) => x.uid === idPath,
      );
      if (!traces || traces.length < 1) return;

      const trace = traces[0];

      newPathHandler(
        map,
        newPath,
        actionBuffer,
        detailsStrategy,
        setPathEditStatus,
        setPointsSelected,
      )(trace.polylineCoords)();

      setObstaclesCosts(
        (detailsStrategy?.costsDetails?.obstacles?.items || []).map(
          (obstacleCost) => ({
            uuid: obstacleCost.id,
            cost: obstacleCost.cost,
          }),
        ),
      );
      // setNewPathConfirmationStatus(true);
    }
  }, [detailsStrategy]);

  const handleCheckboxChange = (currentUid: string) => {
    if (detailsStrategy !== undefined && detailsQuote !== undefined) {
      setLoading(true);

      if (quotePost.selectedPath !== '') {
        void setQuoteStrategy(detailsQuote.uid, detailsStrategy.uid, {
          selectedPath: currentUid,
        }).then((response: any) => {
          if (response.success) {
            setQuotePost({ ...quotePost, selectedPath: currentUid });

            if (quoteId && idStrategy) {
              updateQuoteByStrategy(quoteId, idStrategy, currentUid);

              setLoading(false);
            }
          } else {
            console.log(response);
            setLoading(false);
          }
        });
      }
    }
  };

  // update context after run API
  const updateQuoteByStrategy = (
    quoteId: string,
    idStrategy: string,
    currentUid: string,
  ) => {
    const newContext = {
      ...quoteContext,
      data,
    };

    const indexQuote = newContext.data.findIndex(
      (quote) => quote.id == quoteId,
    );
    const indexStrategy = newContext.data[indexQuote].strategies.findIndex(
      (strategy) => strategy.id == idStrategy,
    );
    if (currentUid) {
      newContext.data[indexQuote].strategies[indexStrategy] = {
        ...newContext.data[indexQuote].strategies[indexStrategy],
        selectedPath: currentUid,
      };

      setQuoteContext(newContext);
    }
  };

  const pointCoords: IPointCoords = {
    lat: Number(detailsQuote?.lat),
    lng: Number(detailsQuote?.long),
    label: detailsQuote?.address,
  };

  interface CircularBufferValue {
    action: string;
    data: any;
  }

  const [map, setMap] = useState<Map | null>(null);
  const [isEditingPath, setPathEditStatus] = useState<boolean>(false);
  const newPath = useRef<Polyline | null>(null);
  const [pointsSelected, setPointsSelected] = useState<
    Array<{ index: number; point: LatLng }>
  >([]);
  const actionBuffer = useRef<CircularBufferValue[]>([]);

  const getDefaultEmptyPath = () => {
    if (!detailsStrategy) return undefined;

    const start = detailsStrategy.polylineCoords[0];
    const end = detailsStrategy.polylineCoords.slice(-1)[0];
    const preExistingPath = [start, end] as any;

    return preExistingPath;
  };

  const [obstaclesData, setObstaclesData] = useState<any>([]);
  const [isConfirmingNewPath, setNewPathConfirmationStatus] =
    useState<boolean>(false);

  type a = ReturnType<typeof L.geoJson>;
  const obstaclesLayer = useRef<a>();

  const removeObstaclesLayerCb = () => {
    if (!map) return;
    if (!obstaclesLayer.current) return;
    map?.removeLayer(obstaclesLayer.current as any);
  };

  newPath?.current?.on('editable:vertex:mousedown', removeObstaclesLayerCb);
  newPath?.current?.on('remove', removeObstaclesLayerCb);
  newPath?.current?.on('editable:vertex:delete', removeObstaclesLayerCb);
  newPath?.current?.on('editable:vertex:add', removeObstaclesLayerCb);

  // disable drag when starting to edit the path.
  // useEffect(() => {
  //   if (!map) return;
  //   if (!isEditingPath) return;
  //   if (!(isEditingPath && !isConfirmingNewPath)) return;

  //   if (!dragModeEnabled) {
  //     map.dragging.disable();
  //     map.on('click', selectHandlerMemo);
  //   } else {
  //     map.off('click', selectHandlerMemo);
  //     map.dragging.enable();
  //   }

  //   return () => {
  //     map.off('click', selectHandlerMemo);
  //   };
  // }, [map, isEditingPath, isConfirmingNewPath]);

  const AltPathInfoCardComponent = (trace: AlternativePaths, index: number) => {
    const customLabel = `TRACE${Number(index + 1)}`;

    return (
      <StyledRow
        key={index}
        onClick={() => {
          handleCheckboxChange(trace.uid);
        }}
        id={trace.uid}
      >
        <Col
          md="3"
          className="d-flex align-items-start px-2 flex-column justify-content-center"
        >
          <Form.Check type={`radio`} id={`check-radio-${index}`}>
            <Form.Check.Input
              type={`radio`}
              isValid
              name="trace"
              id={trace.uid}
              checked={!isEditingPath && quotePost.selectedPath === trace.uid}
              onChange={() => {
                handleCheckboxChange(trace.uid);
              }}
            />
            <Form.Check.Label>
              &nbsp;
              {t('quote.details.strategy_quote.update_trace.trace')}
              <span className="label">
                {TraceTypeLabels[customLabel as keyof typeof TraceTypeLabels]}
              </span>
            </Form.Check.Label>
          </Form.Check>
          <Badge bg="primary" className="mb-2 mx-2">
            <div>{getDistance(trace.distance)}</div>
            <div>{t('quote.details.strategy_quote.update_trace.total')}</div>
          </Badge>
          <Badge bg="primary" className="mx-2">
            <div>{getCostValue(trace.cost)}</div>
            <div>
              {t('quote.details.strategy_quote.update_trace.cost_estimated')}
            </div>
          </Badge>
        </Col>
        <Col
          md="8"
          className={`trace-${index}`}
          onClick={() => {
            handleCheckboxChange(trace.uid);
          }}
        >
          <ChartTrace
            key={index}
            alternativePaths={trace}
            displayInfos={false}
            targetType={detailsStrategy?.targetType ?? ''}
          />
        </Col>

        <Col
          md="1"
          className="d-flex align-items-start px-2 flex-column justify-content-center"
        >
          <Button
            className="btn-edit-path"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (!trace) return;

              newPathHandler(
                map,
                newPath,
                actionBuffer,
                detailsStrategy,
                setPathEditStatus,
                setPointsSelected,
              )(trace.polylineCoords)();
            }}
          >
            <EditPathIcon />
          </Button>

          <Button
            size="sm"
            className="btn-delete-path"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (!detailsStrategy) return;
              if (!detailsQuote) return;
              if (!trace) return;

              void deleteQuoteStrategyPath(
                detailsQuote.uid,
                detailsStrategy.uid,
                trace.uid,
              ).then(() => {
                window.location.reload();
              });
            }}
          >
            <DeletePathIcon />
          </Button>
        </Col>
      </StyledRow>
    );
  };

  const initObstacleCosts = (obstaclesData) =>
    (obstaclesData ?? []).map((f, i) => ({
      uuid: f?.properties?.uuid,
      cost: undefined,
    }));
  const [obstaclesCosts, setObstaclesCosts] = useState<CostUserInfo[]>(
    initObstacleCosts(obstaclesData),
  );

  const obstacleMarkersGroupLayer = React.useRef<L.LayerGroup>(
    L.layerGroup([]),
  );
  map?.addLayer(obstacleMarkersGroupLayer.current);

  const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
  } as const;

  const position = 'topleft' as const;
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  const customEditControls = (
    <div className={positionClass} style={{}}>
      <div className="leaflet-control leaflet-bar">
        {isEditingPath ? (
          <PathEditingButtonsComponent
            isEditingPath={isEditingPath}
            isConfirmingNewPath={isConfirmingNewPath}
            newPath={newPath}
            actionBuffer={actionBuffer}
            point={point}
            detailsStrategy={detailsStrategy}
            pointsSelected={pointsSelected}
            setPointsSelected={setPointsSelected}
            setPathEditStatus={setPathEditStatus}
            dragModeEnabled={dragModeEnabled}
            setDragMode={setDragMode}
            setNewPathConfirmationStatus={setNewPathConfirmationStatus}
            setObstaclesData={setObstaclesData}
            obstaclesData={obstaclesData}
            obstaclesLayer={obstaclesLayer}
            obstacleMarkersGroupLayer={obstacleMarkersGroupLayer}
            setObstaclesCosts={setObstaclesCosts}
            obstaclesCosts={obstaclesCosts}
            removeObstaclesLayerCb={removeObstaclesLayerCb}
            detailsQuote={detailsQuote}
            getDefaultEmptyPath={getDefaultEmptyPath}
            setTmpPathInfo={setTmpPathInfo}
          />
        ) : undefined}
      </div>
    </div>
  );

  const buttonAddNewPath = (
    <div className="d-flex justify-content-center align-items-center w-100">
      <Button
        className="btn-new-path"
        onClick={newPathHandler(
          map,
          newPath,
          actionBuffer,
          detailsStrategy,
          setPathEditStatus,
          setPointsSelected,
        )(getDefaultEmptyPath())}
      >
        <NewPathIcon />
        {/* {t([
        `quote.details.strategy_quote.details_costs.labels_cost.specify_costs`,
      ])} */}
        Ajouter Une Alternative
      </Button>
    </div>
  );

  const sidebarContent = (
    <Form className="p-3">
      <Row className="trace-details mt-2">
        <Col md="6">
          <h3>{t('quote.details.strategy_quote.update_trace.title')}</h3>
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Icon
            iconName="XLg"
            color="rgba(45, 38, 75, 1)"
            className="back-icon"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              navigate(`/quote/${quoteId}/strategy/${idStrategy}`, {
                state: { quoteUid },
              });
            }}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {detailsStrategy !== undefined &&
          detailsStrategy?.alternativePaths.length > 0 ? (
            <>
              {detailsStrategy.alternativePaths.map(AltPathInfoCardComponent)}
            </>
          ) : (
            <Alert variant={'warning'}>
              {t('quote.details.strategy_quote.update_trace.errors.empty')}
            </Alert>
          )}

          {buttonAddNewPath}
          <InfosTrace />
        </Col>
      </Row>
    </Form>
  );

  useEffect(() => {
    setObstaclesCosts(initObstacleCosts(obstaclesData));
  }, [obstaclesData]);

  const sidebarEditingContent = (
    <div className="sidebar-editing">
      <div className="header trace-details">
        <h3>{t('quote.details.strategy_quote.edit_trace.title')}</h3>
      </div>
      <div className="container">
        <div
          className={classNames({
            background: true,
            visible: !isConfirmingNewPath,
          })}
        >
          <div className="main">
            {detailsStrategy !== undefined &&
            detailsStrategy?.alternativePaths.length > 0 ? (
              <>
                <EditedPathInfoCardComponent
                  map={map}
                  isEditingPath
                  obstaclesData={obstaclesData}
                  setObstaclesData={setObstaclesData}
                  pathBbox={newPath.current?.getBounds()}
                  info={tmpPathInfo}
                  distance={
                    newPath.current
                      ? length(newPath.current.toGeoJSON(), {
                          units: 'meters',
                        })
                      : -1
                  }
                  obstaclesCosts={obstaclesCosts}
                  setObstaclesCosts={setObstaclesCosts}
                  obstacleMarkersGroupLayer={obstacleMarkersGroupLayer}
                  obstaclesLayer={obstaclesLayer}
                />
              </>
            ) : (
              <Alert variant={'warning'}>
                {t('quote.details.strategy_quote.update_trace.errors.empty')}
              </Alert>
            )}
          </div>
        </div>
        <div
          className={classNames({
            overlay: true,
            visible: !isConfirmingNewPath,
          })}
        >
          <button
            className="centered-button"
            onClick={() => {
              if (!quoteId) {
                console.warn('no quoteId, aborting path verification.');
                return;
              }

              if (!detailsStrategy?.uid) {
                console.warn('no idStrategy, aborting path verification.');
                return;
              }

              verifyPathHandler(
                quoteUid,
                detailsStrategy?.uid,
                map,
                newPath,
                setNewPathConfirmationStatus,
                setPointsSelected,
                setObstaclesData,
                actionBuffer,
                removeObstaclesLayerCb,
                obstaclesLayer,
                setTmpPathInfo,
                obstacleMarkersGroupLayer,
              )();
            }}
          >
            <VerifyIcon />
            vérifier le tracé
          </button>
        </div>
      </div>

      <div className="footer">
        <Button
          className="cancel-button"
          onClick={cancelPathHandler(
            map,
            newPath,
            actionBuffer,
            setPathEditStatus,
            setPointsSelected,
          )}
        >
          <Icon iconName="XCircle" />
          Annuler
        </Button>
        <Button
          className={classNames({
            'save-button': true,
            disabled: !(isEditingPath && isConfirmingNewPath),
          })}
          disabled={!(isEditingPath && isConfirmingNewPath)}
          onClick={() =>
            savePathHandler(
              newPath,
              detailsStrategy,
              detailsQuote,
              setPathEditStatus,
              setPointsSelected,
              actionBuffer,
              obstaclesCosts,
              obstaclesData.filter(
                (obstacle) => obstacle?.properties?.userDefined,
              ),
            )()
          }
        >
          <Icon iconName="Save2Fill" />
          Sauvegarder
        </Button>
      </div>
    </div>
  );

  const StyledAlternativePathsListComponent = (
    <StyledContainer fluid>
      <Row className={'quote'}>
        <Col md="6" lg="6" xxl="5" className="quote-details">
          {!(isEditingPath || isConfirmingNewPath)
            ? sidebarContent
            : sidebarEditingContent}
        </Col>
        <Col md="6" lg="6" xxl="7" className="map">
          {detailsQuote != null &&
            detailsStrategy != null &&
            quotePost?.selectedPath !== undefined &&
            (isEditingPath || isConfirmingNewPath ? (
              <MapQuoteTrace
                isLoading={isLoading}
                strategy={{
                  ...detailsStrategy,
                  alternativePaths: detailsStrategy.alternativePaths.filter(
                    (p) => p.uid === quotePost?.selectedPath,
                  ),
                }}
                pathSelected={isEditingPath ? '' : quotePost?.selectedPath}
                pointCoords={pointCoords}
                setMap={setMap}
                editControls={customEditControls}
              />
            ) : (
              <MapQuoteTrace
                isLoading={isLoading}
                strategy={detailsStrategy}
                pathSelected={isEditingPath ? '' : quotePost?.selectedPath}
                pointCoords={pointCoords}
                setMap={setMap}
                editControls={customEditControls}
              />
            ))}
        </Col>
      </Row>
    </StyledContainer>
  );

  return (
    <>
      {tableItem.length > 0 && !isLoading ? (
        StyledAlternativePathsListComponent
      ) : (
        <Loading />
      )}
    </>
  );
};

export default UpdateTrace;
