import { newPathActiveStyle } from './pathStyle';
import { type t1 } from './types';

export const changePathHandler =
  (newPath: t1, setNewPathConfirmationStatus: any) => () => {
    if (newPath.current == null) return;
    newPath.current.enableEdit();
    newPath.current.setStyle(newPathActiveStyle);
    setNewPathConfirmationStatus(false);
  };
