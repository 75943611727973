/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// import { t } from 'i18next';
import React from 'react';
import { Form, Row, ListGroup, Button, InputGroup } from 'react-bootstrap';

import { LatLng, type Map } from 'leaflet';
import { center } from '@turf/turf';
import { Icon } from 'shared/Icon';

import { ReactComponent as IncompleteObstacleIcon } from '../../../images/ObstacleToDefine2.svg';
import { ReactComponent as CompleteObstacleIcon } from '../../../images/ObstacleDefined.svg';
import { removeAt, replaceAt } from 'utils/helpers';
import {
  IconObstacleEditedMarker,
  IconObstacleUneditedMarker,
} from './IconObstacleUneditedMarker';
import { customGetLayer } from './UpdateTrace';

// const sleep = async (ms: number): Promise<void> => {
//   await new Promise((resolve) => setTimeout(resolve, ms));
// };

export interface CostUserInfo {
  uuid: string;
  cost: number | undefined;
}

export const ObstacleSectionComponent: React.FC<{
  map: Map | null;
  pathBbox: any;
  obstaclesData: any[];
  // obstacles: Map<string, {}>;
  setObstaclesData: any;
  obstaclesCosts: CostUserInfo[];
  setObstaclesCosts: (state: any) => void;
  obstacleMarkersGroupLayer: React.MutableRefObject<L.LayerGroup<any>>;
  obstaclesLayer: React.MutableRefObject<L.GeoJSON | undefined>;
}> = ({
  map,
  pathBbox,
  obstaclesData,
  setObstaclesData,
  obstaclesCosts,
  setObstaclesCosts,
  obstacleMarkersGroupLayer,
  obstaclesLayer,
}): JSX.Element => {
  const isCostUndefined = (val: CostUserInfo): boolean =>
    !(!!val && (!!val.cost || val.cost === 0));

  return (
    <>
      <div>
        <CompleteObstacleIcon
          style={{
            display: 'inline',
            width: '32px',
            height: '32px',
          }}
        />
        {obstaclesCosts.length} Obstacles
        <IncompleteObstacleIcon
          style={{
            display: 'inline',
            width: '32px',
            height: '32px',
          }}
        />
        {obstaclesCosts.filter(isCostUndefined).length} A traiter
      </div>
      <ListGroup>
        {obstaclesData.map((feature, i) => (
          <ListGroup.Item
            className="obstacle-card-item"
            key={i}
            // action
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              console.log(
                'click target:',
                e.target,
                'currentTarget',
                e.currentTarget,
              );

              // if (e.target !== e.currentTarget) return;

              if (map == null) return;
              const d = center(feature);
              const lat = d.geometry.coordinates[1];
              const lng = d.geometry.coordinates[0];

              // TODO: zoom out to a view that fits the path and zoom in again to give context.

              // console.log('center / lat /lng', d, lat, lng);
              // const targetZoom = 20;
              // const targetZoom = 17;
              const targetPoint = new LatLng(lat, lng);
              // if (map.getZoom() < targetZoom)
              //   map.setZoomAround(targetPoint, targetZoom);
              // else map.panTo(targetPoint);

              if (!map.getBounds().contains(targetPoint))
                map.flyTo(targetPoint);

              // map.fitBounds(pathBbox);
              // const fittedZoomLevel = map.getZoom();
              // const fittedCenter = map.getCenter();
              // console.log(
              //   'fitted zoom level:',
              //   fittedZoomLevel,
              //   'center:',
              //   fittedCenter,
              // );

              // //   const fittedZoomLevel = 14;
              // //   const fittedCenter = 14;

              // map.flyTo(fittedCenter, fittedZoomLevel);
              // // map.flyTo(targetPoint, targetZoom / 2);
              // void sleep(15 * 100).then(() => {
              //   map.flyTo(targetPoint, targetZoom);
              // });

              //
              // obstacleMarkersGroupLayer.get
            }}
          >
            <ObstacleItem
              feature={feature}
              i={i}
              isCostUndefined={isCostUndefined}
              obstaclesData={obstaclesData}
              obstaclesCosts={obstaclesCosts}
              setObstacles={setObstaclesData}
              setObstaclesCosts={setObstaclesCosts}
              obstacleMarkersGroupLayer={obstacleMarkersGroupLayer}
              obstaclesLayer={obstaclesLayer}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

interface ObstacleItemProps {
  feature: any;
  i: number;
  isCostUndefined: (val: CostUserInfo) => boolean;
  obstaclesData: any[];
  obstaclesCosts: CostUserInfo[];
  setObstacles: {
    map: Map | null;
    pathBbox: any;
    obstaclesData: any[];
    setObstacles: any;
    obstaclesCosts: CostUserInfo[];
    setObstaclesCosts: (state: any) => void;
  }['setObstacles'];
  setObstaclesCosts: (state: any) => void;
  obstacleMarkersGroupLayer: React.MutableRefObject<L.LayerGroup<any>>;
  obstaclesLayer: React.MutableRefObject<L.GeoJSON | undefined>;
}

function ObstacleItem({
  feature,
  i: index,
  isCostUndefined,
  obstaclesData,
  obstaclesCosts,
  setObstacles,
  setObstaclesCosts,
  obstacleMarkersGroupLayer,
  obstaclesLayer,
}: ObstacleItemProps): JSX.Element {
  return (
    <>
      <Row className="obstacle-card">
        <Row
          title={feature.properties.description}
          style={{
            overflowWrap: 'anywhere',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {/* <Icon
              iconName="GeoAltFill"
              style={{
                display: 'inline',
                width: '32px',
                height: '32px',
              }}
            /> */}
          {!isCostUndefined(obstaclesCosts[index]) ? (
            <CompleteObstacleIcon
              style={{
                display: 'inline',
                width: '32px',
                height: '32px',
              }}
            />
          ) : (
            <IncompleteObstacleIcon
              style={{
                display: 'inline',
                width: '32px',
                height: '32px',
              }}
            />
          )}

          {!feature?.properties?.userDefined ? (
            <span
              className="tittle"
              style={{
                width: 'auto',
                alignContent: 'center',
              }}
            >
              Traversée Obstacle générique (
              {(feature?.properties?.cid as string) ?? ''})
            </span>
          ) : (
            <input
              type="text"
              // size=/{10}
              className="title"
              defaultValue={`${
                (feature?.properties?.description as string) ?? ''
              }`}
              style={{
                width: '90%',
                alignContent: 'center',
                border: 'none',
                backgroundColor: 'transparent',
              }}
            />
          )}
        </Row>

        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <Form.Group className="contact-form" controlId="obstacleCost">
            <span className="header">{'Surcoût engendré'}</span>
            <InputGroup
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();

                const input = (e.target as any).value?.replace(',', '.');
                const numberRaw = parseFloat(input);
                const isInvalid = /[^0-9.]/i.test(input) || isNaN(numberRaw);
                const newValue = isInvalid ? undefined : numberRaw;

                const marker = customGetLayer(
                  obstacleMarkersGroupLayer.current,
                  feature?.properties?.uuid,
                );

                marker?.setIcon(
                  isInvalid
                    ? IconObstacleUneditedMarker
                    : IconObstacleEditedMarker,
                );

                setObstaclesCosts(
                  replaceAt(obstaclesCosts, index, {
                    ...obstaclesCosts[index],
                    cost: newValue,
                  }),
                );

                // console.log(
                //   'Input change:',
                //   newValue,
                //   'uuid',
                //   feature?.properties?.uuid,
                //   'marker',
                //   marker,
                //   'Layer',
                //   obstacleMarkersGroupLayer.current,
                // );
              }}
            >
              <Form.Control aria-label="Prix" />
              <InputGroup.Text>k€</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Button
            className="obstacle-delete-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              obstaclesLayer.current?.eachLayer((layer) => {
                // console.log('geojson child layer', layer);
                if (
                  (layer as any).feature?.properties?.uuid ===
                  feature?.properties?.uuid
                ) {
                  obstaclesLayer.current?.removeLayer(layer);
                }
              });

              const marker = customGetLayer(
                obstacleMarkersGroupLayer.current,
                feature?.properties?.uuid,
              );
              obstacleMarkersGroupLayer.current?.removeLayer(marker);
              setObstacles(removeAt(obstaclesData, index));
            }}
          >
            <Icon iconName="Trash" />
          </Button>
        </Row>
      </Row>
    </>
  );
}
